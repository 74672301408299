import { Input } from 'antd'
import styled from 'styled-components'

const BaseInput = styled(Input)`
  border-radius: 6px;

  span.ant-input-wrapper.ant-input-group,
  input.ant-input {
    border-radius: 6px !important;
  }
  .ant-input-group-addon:first-child {
    border-radius: 6px 0px 0px 6px !important;
  }
  .ant-input-group > .ant-input:last-child {
    border-radius: 0px 6px 6px 0px !important;
  }
`
export default BaseInput
