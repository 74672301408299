import React, { memo } from 'react'
import styled, { css } from 'styled-components'
import { addStyles } from 'react-mathquill'

addStyles()

interface StyleInputConverter {
  value?: string | undefined
  width?: string | number
  height?: string | number
  overflowY?: string
  overflowX?: string
  fontSize?: string
  style?: any
  textOverflow?: boolean
}

interface ConverterContainerProps {
  width?: string | number
  height?: string | number
  overflowY?: string
  overflowX?: string
  fontSize?: string
  textOverflow?: boolean
}

const ConverterContainer = styled.div<ConverterContainerProps>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  overflow-y: ${(props) => props.overflowY};
  overflow-x: ${(props) => props.overflowX};
  word-break: break-word;
  font-size: ${(props) => props.fontSize};

  .mq-editable-field,
  .mq-math-mode .mq-editable-field {
    border: none;
    box-shadow: none;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }
  .mq-editable-field.mq-focused,
  .mq-math-mode .mq-editable-field.mq-focused {
    border: none;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }
  .mq-root-block,
  .mq-math-mode .mq-root-block {
    padding: 8px 2px 2px;
  }

  ${(props) => {
    if (props.textOverflow) {
      return css`
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      `
    }
    return null
  }}
`

export default memo(function StyleInputConverter({
  value = '',
  width = '100%',
  height = 'auto',
  overflowY = 'hidden',
  overflowX = 'scroll',
  fontSize = '12px',
  style = {},
  textOverflow = false
}: StyleInputConverter) {
  return (
    <ConverterContainer
      width={width}
      height={height}
      overflowY={overflowY}
      overflowX={overflowX}
      fontSize={fontSize}
      style={...style}
      textOverflow={textOverflow}
      dangerouslySetInnerHTML={{
        __html: value
      }}
    />
  )
})
