import styled from 'styled-components'

interface BoxProps {
  display?: 'flex' | 'inline' | 'block' | 'grid' | 'content'
  direction?: 'row' | 'column'
  justify?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'initial'
    | 'inherit'
  align?:
    | 'stretch'
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'baseline'
    | 'initial'
    | 'inherit'
}

const Box = styled.div<BoxProps>`
  display: ${(props) => (props.display ? props.display : 'flex')};
  flex-direction: ${(props) => props.direction && props.direction};
  justify-content: ${(props) => props.justify && props.justify};
  align-items: ${(props) => props.align && props.align};
`

export default Box
