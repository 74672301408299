import React, { memo, useEffect, useState } from 'react'
import Drawer from 'antd/lib/drawer'
import { Space, Typography } from 'antd'
import Box from 'designSystem/Box'
import BaseButton from 'designSystem/BaseButton'
import BaseInput from 'designSystem/BaseInput'

const AddLinkDrawer = ({
  visible,
  defaultText,
  // onTextChange,
  onAddLink,
  onDiscard
}) => {
  const [link, setLink] = useState('')
  const [text, setText] = useState('')

  useEffect(() => {
    if (defaultText) {
      setText(defaultText)
    }
  }, [defaultText])

  return (
    <Drawer
      visible={visible}
      placement='right'
      maskClosable={false}
      closable={false}
      destroyOnClose={true}
      width='486px'
      title={
        <Box justify='space-between'>
          <Space>
            <Typography style={{ color: '#80879B' }}>Add Link </Typography>
          </Space>
          <Space>
            <BaseButton onClick={() => onDiscard()}>Discard</BaseButton>
            <BaseButton
              type='primary'
              onClick={() => {
                onAddLink({ link: link, text: text })
                setText('')
                setLink('')
              }}
            >
              Confirm
            </BaseButton>
          </Space>
        </Box>
      }
    >
      <Space direction='vertical' size={16} style={{ width: '100%' }}>
        <Space direction='vertical' size={0}>
          <Typography.Text
            strong
            style={{ color: '#242938', fontSize: '16px' }}
          >
            Text Link
          </Typography.Text>
          <Typography.Text style={{ color: '#80879B', fontSize: '14px' }}>
            เพิ่ม Link บนคำที่คุณเลือก
          </Typography.Text>
        </Space>
        <div>
          <Typography.Text style={{ fontWeight: 700 }}>
            Your Word
          </Typography.Text>
          <BaseInput
            value={text}
            disabled={!defaultText ? false : true}
            onChange={({ target: { value } }) => {
              setText(value)
            }}
            placeholder='Enter your word'
          />
        </div>
        <div>
          <Typography.Text style={{ fontWeight: 700 }}>Link</Typography.Text>
          <BaseInput
            addonBefore='Link'
            value={link}
            onChange={({ target: { value } }) => {
              setLink(value)
            }}
            placeholder='Enter link'
          />
        </div>
      </Space>
    </Drawer>
  )
}

export default memo(AddLinkDrawer)
