import React, {
  memo,
  useCallback,
  useState,
  useMemo,
  useEffect,
  useRef
} from 'react'
import styled, { css } from 'styled-components'
import Space from 'antd/lib/space'
import { AdvanceKeyBoardComponent } from 'StyledInputComponent'
import { addStyles } from 'react-mathquill'
import MediaUploadDrawer from 'MediaUploadDrawer/MediaDrawer'
import { Media } from 'MediaUploadDrawer/Interface/Media.interface'
import { Button } from 'antd'
import { v4 as uuidv4 } from 'uuid'
import useHandleAddLink from 'Lib/useHandleAddLink'
import AddLinkDrawer from 'AddLinkDrawer/AddLinkDrawer'
import Box from 'designSystem/Box'
import useSaveSelection from 'Lib/useSaveSelection'

const math_button = (
  <svg
    id='Layer_2'
    data-name='Layer 2'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 24 24'
    style={{ fill: '#80879b', width: '20px', height: '20px' }}
  >
    <path d='M17.913,14.316V5.629a3.671,3.671,0,0,0-3.667-3.667H5.559A3.671,3.671,0,0,0,1.892,5.629v8.687a3.671,3.671,0,0,0,3.667,3.667h8.687A3.671,3.671,0,0,0,17.913,14.316Zm-14.021,0V5.629A1.67,1.67,0,0,1,5.559,3.962h8.687a1.669,1.669,0,0,1,1.667,1.667v8.687a1.669,1.669,0,0,1-1.667,1.667H5.559A1.67,1.67,0,0,1,3.892,14.316Z' />
    <path d='M20.926,6.424a1,1,0,0,0-1,1v10.82a1.787,1.787,0,0,1-1.836,1.729H6.744a1,1,0,0,0,0,2H18.09a3.788,3.788,0,0,0,3.836-3.729V7.424A1,1,0,0,0,20.926,6.424Z' />
    <path d='M12.435,7.97h1.61l.477-1.747H8.05a2.473,2.473,0,0,0-1.071.21,2.183,2.183,0,0,0-.83.781,5.423,5.423,0,0,0-.663,1.468l.21.1A1.645,1.645,0,0,1,7.274,7.97h.849a10.077,10.077,0,0,1-.938,2.168,7.73,7.73,0,0,1-1.206,1.521,4.554,4.554,0,0,0-.55.615.923.923,0,0,0,.13,1.157.984.984,0,0,0,.752.291A1.343,1.343,0,0,0,7.464,13.1a10.682,10.682,0,0,0,1.031-2.513L9.329,7.97h1.6L10.113,10.2a8.212,8.212,0,0,0-.6,2.233q0,1.287,1.545,1.286,1.69,0,2.233-2.006h-.372q-.284.713-.842.712-.671,0-.671-.769a6.3,6.3,0,0,1,.356-1.585Z' />
  </svg>
)

const LinkIcon = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M12.762 2.35141C12.1318 1.74483 11.2869 1.41261 10.4123 1.42748C9.53768 1.44235 8.70459 1.80309 8.09533 2.43075L7.14866 3.41675C7.03689 3.54582 6.9789 3.71286 6.98665 3.88343C6.99441 4.054 7.06731 4.21509 7.19033 4.33349C7.31335 4.4519 7.47711 4.51859 7.64785 4.51981C7.81859 4.52104 7.98329 4.45671 8.108 4.34008L9.05266 3.35608C9.41892 2.99841 9.91018 2.79759 10.4221 2.79626C10.934 2.79494 11.4263 2.99322 11.7944 3.349C12.1625 3.70478 12.3774 4.19003 12.3935 4.70171C12.4096 5.21339 12.2257 5.71119 11.8807 6.08941L9.72266 8.32341C9.42874 8.5663 9.07868 8.73173 8.70442 8.80463C8.33015 8.87752 7.9436 8.85556 7.58 8.74075C7.49818 8.70954 7.41102 8.69474 7.32349 8.69722C7.23597 8.69969 7.14978 8.71938 7.06986 8.75516C6.98994 8.79095 6.91785 8.84212 6.85771 8.90576C6.79756 8.9694 6.75054 9.04427 6.71933 9.12608C6.68812 9.20789 6.67333 9.29505 6.6758 9.38258C6.67828 9.47011 6.69797 9.5563 6.73375 9.63622C6.76953 9.71614 6.8207 9.78823 6.88434 9.84837C6.94799 9.90852 7.02285 9.95553 7.10466 9.98675C7.46103 10.119 7.83854 10.1852 8.21866 10.1821C9.10715 10.1823 9.9665 9.86524 10.642 9.28808L12.842 7.01608C13.4482 6.38601 13.7801 5.54145 13.7652 4.66723C13.7502 3.79301 13.3895 2.96033 12.762 2.35141Z'
      fill='#80879B'
    />
    <path
      d='M8.11135 11.4399L6.94735 12.6439C6.58109 13.0016 6.08984 13.2024 5.5779 13.2037C5.06597 13.2051 4.57369 13.0068 4.20559 12.651C3.83749 12.2952 3.62257 11.81 3.60647 11.2983C3.59036 10.7866 3.77435 10.2888 4.11935 9.91058L6.31935 7.63925C6.58719 7.36197 6.93063 7.16953 7.30696 7.08585C7.68328 7.00217 8.07591 7.03094 8.43602 7.16858C8.51783 7.19975 8.60498 7.2145 8.69249 7.21198C8.78001 7.20947 8.86617 7.18974 8.94606 7.15393C9.02595 7.11812 9.098 7.06692 9.1581 7.00326C9.2182 6.9396 9.26518 6.86473 9.29635 6.78292C9.32752 6.7011 9.34226 6.61395 9.33975 6.52644C9.33723 6.43892 9.31751 6.35276 9.2817 6.27287C9.24588 6.19299 9.19469 6.12093 9.13103 6.06083C9.06737 6.00073 8.9925 5.95375 8.91068 5.92258C8.30671 5.69184 7.64825 5.64364 7.01711 5.78398C6.38597 5.92431 5.80998 6.24698 5.36068 6.71192L3.16068 8.98391C2.5616 9.6149 2.23589 10.4569 2.25441 11.3268C2.27293 12.1967 2.63417 13.0241 3.25956 13.629C3.88496 14.2339 4.72391 14.5674 5.59392 14.557C6.46394 14.5465 7.29465 14.193 7.90535 13.5732L9.07001 12.3699C9.19299 12.2428 9.26042 12.072 9.25748 11.8952C9.25455 11.7183 9.18148 11.5499 9.05435 11.4269C8.92722 11.3039 8.75645 11.2365 8.5796 11.2394C8.40276 11.2424 8.23432 11.3155 8.11135 11.4426V11.4399Z'
      fill='#80879B'
    />
  </svg>
)

const media_button = (
  <svg
    id='Layer_2'
    data-name='Layer 2'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 24 24'
    style={{ fill: '#80879b', width: '20px', height: '20px' }}
  >
    <path d='M13.766,8.758A1.645,1.645,0,1,0,12.124,7.11,1.646,1.646,0,0,0,13.766,8.758Z' />
    <path d='M22.007,5.719a1,1,0,0,0-1-1h0l-2.4.009,0-1.568A1.163,1.163,0,0,0,17.445,2L3.181,1.975a1.163,1.163,0,0,0-1.162,1.16L1.992,17.4a1.164,1.164,0,0,0,1.16,1.162l1.666,0,.009,2.463a1,1,0,0,0,1,1h0l15.24-.06a1,1,0,0,0,1-1ZM14.629,18.582l.006,1.406L12.111,20l-.006-1.42Zm3.962-8.4,1.434,0,.025,6.445-1.471.018Zm1.42-3.46.006,1.455-1.422,0,0-1.455ZM16.6,4l-.01,5.176c0,.006,0,.012,0,.018s0,.011,0,.017l-.008,4.059L10.456,9.347A2.97,2.97,0,0,0,7.165,9.4L4,11.584l.015-7.608ZM4,14.012c.006,0,.013,0,.019-.008L8.3,11.05a.971.971,0,0,1,1.077-.018L16.5,15.594c.024.015.051.021.076.034l0,.958L3.993,16.561Zm2.82,4.555,3.287.006.006,1.432-3.287.013Zm9.817,1.413-.005-1.311,3.428-.041,0,1.338Z' />
  </svg>
)

addStyles()

interface StyleInputProps {
  value?: string | undefined
  width?: string | number
  height?: string
  isLimitCharacter?: boolean
  characterLength?: number
  style?: any
  imageReceive?: string
  youtubeReceive?: string
  audioReceive?: string
  onChange?: (value: string | undefined) => void
  onInsertHTML?: (value: string | undefined) => void
  uploadFunction?: (media: Media) => void
  uploadEnable?: boolean
  isModalOpen?: any
  setIsModalOpen?: any
  placeholder?: string
  hideOption?: boolean
  normalInput?: boolean
  linkEnable?: boolean
  mathEnable?: boolean
  boldEnable?: boolean;
  italicEnable?: boolean;
  underlineEnable?: boolean;
  colorPickerEnable?: boolean
}

const InputContainer = styled.div<{
  width: string | number
  height: string
  isLimitCharacter: boolean
  isInputFocus: boolean
  hideOption: boolean
  normalInput: boolean
}>`
  position: relative;
  width: ${(props) => props.width};
  height: ${(props) => {
    if (props.normalInput) {
      return props.height
    }
    if (!props.normalInput) {
      return props.hideOption
        ? props.isInputFocus
          ? `calc(${props.height} + 32px)`
          : props.height
        : `calc(${props.height} + 32px)`
    }
    return props.height
  }};
  min-width: 200px;
  min-height: 36px;
  transition-duration: 0.4s;

  border-radius: ${(props) => {
    if (!props.normalInput) {
      return '6px 6px 6px 6px'
    }
    return '6px 6px 6px 6px'
  }};
  border-left: 1px solid #e4e4eb;
  border-top: 1px solid #e4e4eb;
  border-right: 1px solid #e4e4eb;
  border-bottom: ${(props) => {
    if (!props.normalInput) {
      return '1px solid #e4e4eb'
    }
    return '1px solid #e4e4eb'
  }};
`

const InputBox = styled.div<{
  isInputFocus: boolean
  hideOption: boolean
  normalInput: boolean
}>`
  position: relative;
  display: flex;
  flex-direction: ${(props) => (props.normalInput ? 'row' : 'column')};
  width: 100%;
  height: ${(props) => {
    if (!props.normalInput) {
      return props.hideOption
        ? props.isInputFocus
          ? ' calc(100% - 32px) '
          : '100%'
        : ' calc(100% - 32px) '
    }
    return '100%'
  }};

  min-height: 32px;
  padding: 4px 4px 4px 8px;
`

const InputFooter = styled.div<{
  isInputFocus: boolean
  hideOption: boolean
  height: string | number
}>`
  display: flex;
  position: ${(props) =>
    props.hideOption ? (props.isInputFocus ? 'static' : 'absolute') : 'static'};
  justify-content: space-between;
  width: 100%;
  height: 32px;
  padding: 0 8px 0 8px;
  background: #fafafc;
  border-top: 1px solid #e4e4eb;
  box-sizing: border-box;
  border-radius: 0px 0px 6px 6px;
  top: ${(props) =>
    props.hideOption
      ? props.isInputFocus
        ? `calc(100% - 32px)`
        : props.height
          ? `calc(${props.height} - 32px)`
          : '32px'
      : 'none'};
  z-index: ${(props) =>
    props.hideOption ? (props.isInputFocus ? 'none' : -1) : -1};
  opacity: ${(props) => (props.hideOption ? (props.isInputFocus ? 1 : 0) : 1)};
  transition-duration: 0.4s;
`

const Color = styled.label<{ background: string }>`
  display: block;
  width: 20px;
  height: 20px;
  background: ${(props) => props.background};
  border: 1px solid #e4e4eb;
  border-radius: 6px;
  cursor: pointer;
`

const StyleButton = styled.button<{ isActive: boolean }>`
  width: 26px;
  height: 26px;
  background: ${(props) => (props.isActive ? 'white' : 'none')};
  border: ${(props) => (props.isActive ? '1px solid #e4e4eb' : 'none')};
  color: ${(props) => (props.isActive ? 'black' : '#80879B')};
  font-weight: ${(props) => (props.isActive ? 'bold' : 400)};
  border-radius: 6px;
  cursor: pointer;
  transition-duration: 0.4s;
  &:hover {
    background: white;
    border: 1px solid #bec3ce;
  }

  &:active {
    transform: scale(0.9);
  }
`

const InputEditable = styled.div<{ normalInput: boolean; height: string }>`
  width: 100%;
  height: ${(props) => (props.height.includes('auto') ? 'auto' : '100%')};

  ${(props) => {
    if (props.normalInput) {
      return css`
        display: flex;
        align-items: center;
        justify-content: flex-start;
        overflow-y: hidden;
        overflow-x: scroll;
        white-space: nowrap;
      `
    }
    if (!props.normalInput) {
      return css`
        overflow-y: scroll;
      `
    }
    return css`
      overflow-y: scroll;
      overflow-x: scroll;
    `
  }};

  &:focus {
    outline: none;
  }
  .mq-editable-field,
  .mq-math-mode .mq-editable-field {
    border: 1px solid;
    border-radius: 6px;
    border-color: #e4e4eb;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }

  .mq-editable-field.mq-focused,
  .mq-math-mode .mq-editable-field.mq-focused {
    border: 1px solid;
    border-radius: 6px;
    border-color: #e4e4eb;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }
  .mq-root-block,
  .mq-math-mode .mq-root-block {
    padding: 8px 2px 2px;
  }
  ::-webkit-scrollbar {
    width: 4px;
    height: ${(props) => (props.normalInput ? '0' : '4px')};
    background: none;
  }
  ::-webkit-scrollbar-track {
    background: none;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 16px;
    background: #7a7b7d;
  }
  &:empty:before {
    content: attr(placeholder);
    color: #bec3ce;
  }
`

const WordLength = styled.div<{ normalInput: boolean }>`
  position: ${(props) => (props.normalInput ? 'flex' : 'absolute')};
  width: min-content;
  height: auto;
  bottom: 4px;
  right: 8px;
  text-align: end;
  color: #bec3ce;
  font-size: 12px;
  background: #ffffff;
  /* border-radius: 60px; */
  padding: 4px 4px 4px 4px;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
`

const BASE_TEXT_INPUT_COLOR = '#000001'

const INITIAL_COLOR = [
  '#000001',
  '#378EF0',
  '#33AB84',
  '#7575F1',
  '#EC5B62',
  '#F29423',
  '#F3D600',
  '#92CF88',
  '#242938',
  '#363C4D',
  '#7A7B7D'
]

export default memo(function StyleInput({
  value = '',
  width = '100%',
  height = 'auto',
  isLimitCharacter = false,
  characterLength = 500,
  style = {},
  onChange = () => { },
  uploadFunction,
  uploadEnable = false,
  imageReceive,
  youtubeReceive,
  audioReceive,
  isModalOpen,
  setIsModalOpen,
  placeholder = '',
  hideOption = true,
  normalInput = false,
  linkEnable = false,
  mathEnable = true,
  boldEnable = true,
  italicEnable = true,
  underlineEnable = true,
  colorPickerEnable = true
}: StyleInputProps) {
  const [pasteText, setPasteText] = useState<string>('')
  const [textColor, setTextColor] = useState<string>('#000001')
  const [isBold, setIsBold] = useState<boolean>(false)
  const [isItalic, setIsItalic] = useState<boolean>(false)
  const [isUnderLine, setIsUnderLine] = useState<boolean>(false)
  const [isInputFocus, setIsInputFocus] = useState<boolean>(false)
  const [isInputBlur, setIsInputBlur] = useState<boolean>(false)

  const [isVisible, setIsVisible] = useState(false)
  const [previousFocusNode, setPreviousFocusNode] = useState<Node>()
  const [previousFocusOffset, setPreviousFocusOffset] = useState<any>(0)
  const [previousSelection, setPreviousSelection] = useState<Selection>()
  const [insertValue, setInsertValue] = useState<any>('')
  // const [valueTrigger, setValueTrigger] = useState<boolean>(false)
  const [isOnInput, setIsOnInput] = useState<boolean>(false)

  const [wordCount, setWordCount] = useState<number>(0)
  const [htmlValue, setHtmlValue] = useState<string>('')
  const {
    visibleAddLinkDrawer,
    openAddLinkDrawer,
    selectedTextForAddLink,
    keepSelectedText,
    closeAddLinkDrawer
  } = useHandleAddLink()
  const { saveRef, selRange, saveSelection, restoreSelection } =
    useSaveSelection()

  const ref = useRef<any>()

  const styleInputId = uuidv4()

  const showDrawer = () => {
    setIsModalOpen(true)
  }

  const onClose = () => {
    setIsModalOpen(false)
  }

  const limitCharacter = useMemo(() => {
    return (
      <WordLength normalInput={normalInput}>
        {wordCount + '/' + characterLength}
      </WordLength>
    )
  }, [characterLength, wordCount])

  const inputOption = useMemo(() => {
    if (normalInput) {
      return (
        <div
          style={{
            display: 'flex',
            width: 'auto',
            height: 'auto',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {isLimitCharacter && limitCharacter}
          {normalInput && mathEnable && (
            <Button
              size='small'
              type='link'
              icon={math_button}
              // disabled={!isInputFocus}
              onClick={() => handleInsertAdvanceKeyboard()}
            />
          )}
        </div>
      )
    }
    if (!normalInput) {
      return limitCharacter
    }
    return ''
  }, [normalInput, limitCharacter, mathEnable])

  const handleBold = useCallback(() => {
    // @ts-ignore
    ref.current.focus()
    document.execCommand('styleWithCSS', false, 'true')
    document.execCommand('bold', false)
    setIsBold((prev) => {
      return !prev
    })
  }, [])

  const handleItalic = useCallback(() => {
    // @ts-ignore
    ref.current.focus()
    document.execCommand('styleWithCSS', false, 'true')
    document.execCommand('italic', false)
    setIsItalic((prev) => {
      return !prev
    })
  }, [])

  const handleColor = useCallback((color) => {
    // @ts-ignore
    ref.current.focus()
    document.execCommand('styleWithCSS', false, 'true')
    document.execCommand('foreColor', false, color)
    setTextColor(() => {
      return color
    })
  }, [])

  const handleUnderline = useCallback(() => {
    // @ts-ignore
    ref.current.focus()
    document.execCommand('styleWithCSS', false, 'true')
    document.execCommand('underline', false)
    setIsUnderLine((prev) => {
      return !prev
    })
  }, [])

  const handleAddLink = useCallback(({ link, text }) => {
    let newLink = link
    if (link.indexOf("http://") === -1 || link.indexOf("https://") === -1 || link.indexOf("www.") === -1) {
      console.log('link ; ', link)
      newLink = "https://" + link
    }
    if (saveRef.current.toString()) {
      let range = saveRef.current.getRangeAt(0)
      range.deleteContents()
      console.log('newLink : ', newLink)

      document.execCommand(
        'insertHTML',
        false,
        '<a style="color:#56a0ef" href="' + newLink + '"target="popup" rel="noopener noreferrer">' + text + '</a>' + '&nbsp'
      )
      saveRef.current.removeAllRanges()
      range = range.cloneRange()
      range.collapse(false)
      saveRef.current.addRange(range)
    } else {
      document.execCommand(
        'insertHTML',
        false,
        '<a style="color:#56a0ef" href="' + newLink + '" target="popup" rel="noopener noreferrer">' + text + '</a>' + '&nbsp'
      )
    }

    // !saveRef.current.toString() ? setCaret() : saveRef.current.addRange(range)
  }, [])

  const handleSpecialKey = useCallback(
    (e) => {
      if (e.keyCode === 9) {
        document.execCommand('insertHTML', false, '&nbsp;&nbsp;&nbsp;&nbsp;')
        e.preventDefault()
      }
      if (e.keyCode === 13) {
        if (normalInput) {
          e.preventDefault()
        }
        if (!normalInput) {
          document.execCommand('insertHTML', false, '<br><br>')
          e.preventDefault()
        }
      }
    },
    [styleInputId]
  )

  const handleGetHTML = useCallback(() => {
    // const document = ref.current
    // const s = new XMLSerializer()
    // const template = document.removeAttribute('contenteditable')
    // const str = s.serializeToString(template)
    onChange(ref.current.innerHTML)
  }, [onChange])

  const clearOptionActive = useCallback(() => {
    setIsBold(false)
    setIsItalic(false)
    setIsUnderLine(false)
    setTextColor('#000001')
  }, [])

  const getStyleState = useCallback((currentElement: any) => {
    let isStyleBold = false
    let isStyleItalic = false
    let isStyleUnderLine = false
    let styleColor = BASE_TEXT_INPUT_COLOR

    let i = currentElement
    while (i.parentElement.nodeName === 'SPAN') {
      const parentElement = i.parentElement
      const style = i.parentElement.style
      if (style.fontWeight === 'bold') {
        isStyleBold = true
      }
      if (style.fontStyle === 'italic') {
        isStyleItalic = true
      }
      if (style.textDecorationLine === 'underline') {
        isStyleUnderLine = true
      }
      if (style.color) {
        styleColor = style.color
      }
      i = parentElement
    }

    return {
      isBold: isStyleBold,
      isItalic: isStyleItalic,
      isUnderline: isStyleUnderLine,
      color: styleColor
    }
  }, [])

  const setStyle = useCallback(
    (
      focusStyleState: boolean,
      anchorStyleState: boolean,
      focusOffset: number,
      anchorOffset: number,
      setStyle: (status: boolean) => void
    ) => {
      if (focusOffset === anchorOffset) {
        setStyle(focusStyleState)
      }
      if (focusOffset !== anchorOffset) {
        if (focusStyleState !== anchorStyleState) {
          if (focusOffset < anchorOffset) {
            setStyle(focusStyleState)
          }
          if (focusOffset > anchorOffset) {
            setStyle(anchorStyleState)
          }
        }
        if (focusStyleState === anchorStyleState) {
          setStyle(focusStyleState)
        }
      }
    },
    []
  )

  const handleStyle = useCallback(
    (focus: any, anchor: any, focusOffset: number, anchorOffset: number) => {
      const focusStyleState = getStyleState(focus)
      const anchorStyleState = getStyleState(anchor)

      setStyle(
        focusStyleState.isBold,
        anchorStyleState.isBold,
        focusOffset,
        anchorOffset,
        setIsBold
      )
      setStyle(
        focusStyleState.isItalic,
        anchorStyleState.isItalic,
        focusOffset,
        anchorOffset,
        setIsItalic
      )
      setStyle(
        focusStyleState.isUnderline,
        anchorStyleState.isUnderline,
        focusOffset,
        anchorOffset,
        setIsUnderLine
      )
      if (textColor !== focusStyleState.color) {
        setTextColor(focusStyleState.color)
      }
    },
    [getStyleState, setStyle, textColor]
  )

  const setCaret = useCallback(() => {
    const el = ref.current
    const selection = window.getSelection()
    const range = document.createRange()
    // @ts-ignore
    selection.removeAllRanges()
    // @ts-ignore
    range.selectNodeContents(el)
    range.collapse(false)
    // @ts-ignore
    selection.addRange(range)
    // @ts-ignore
    // el.focus()
  }, [])

  useEffect(() => {
    document.addEventListener('click', (event: any) => {
      // console.log(typeof event)
      const popElement = document?.getElementsByClassName('mq-math-mode')
      // console.log(popElement)
      for (let i = 0; i < popElement?.length || 0; i++) {
        const popEl = popElement[i]
        const isClickInside = popEl.contains(event.target)
        if (!isClickInside) {
          // if (!isInputFocus) {
          //   // setCaret()
          // }
        } else {
          // console.log('Inside')
          break
        }
      }
    })
    return () => {
      document.removeEventListener('click', () => null)
    }
  }, [])

  const onInput = useCallback(
    (e: any, fromEvent: 'input' | 'paste') => {
      if (fromEvent === 'input') {
        const text = e.target.innerText
        const limitText = text.substr(0, characterLength)
        if (isLimitCharacter) {
          if (text.length <= characterLength) {
            if (pasteText.length) {
              setPasteText('')
              setHtmlValue(e.target.innerHTML)
            }

            if (pasteText.length === 0) {
              setHtmlValue(e.target.innerHTML)
            }
            setWordCount(text.length)
            handleGetHTML()
          }
          if (text.length > characterLength) {
            if (pasteText.length) {
              setPasteText('')
              setHtmlValue(e.target.innerHTML)
            }

            if (pasteText.length === 0) {
              ref.current.innerHTML = htmlValue
              setCaret()
            }
            setWordCount(limitText.length)
            handleGetHTML()
          }
        }
        if (!isLimitCharacter) {
          handleGetHTML()
        }
      }
    },
    [
      characterLength,
      handleGetHTML,
      htmlValue,
      isLimitCharacter,
      setCaret,
      pasteText
    ]
  )

  const checkInputStyle = useCallback(() => {
    const selection = window?.getSelection()
    const currentFocusElementStyle = selection?.focusNode
    const currentAnchorElementStyle = selection?.anchorNode
    handleStyle(
      currentFocusElementStyle,
      currentAnchorElementStyle,
      // @ts-ignore
      selection?.focusOffset,
      selection?.anchorOffset
    )
  }, [handleStyle])

  useEffect(() => {
    window.addEventListener('click', () => {
      const activeElement = document.activeElement
      const inputElement = ref.current
      // @ts-ignore
      if (activeElement === inputElement || isModalOpen || isVisible) {
        setIsInputFocus(true)
      }
      if (
        // @ts-ignore
        (activeElement !== inputElement && !isModalOpen && !isVisible) ||
        activeElement === undefined ||
        inputElement === undefined
      ) {
        setIsInputFocus(false)
      }
    })
  }, [setIsInputFocus, isModalOpen, isVisible])

  useEffect(() => {
    if (!isInputFocus) {
      clearOptionActive()
    }
  }, [clearOptionActive, isInputFocus])

  useEffect(() => {
    if (!isOnInput) {
      ref.current.innerHTML = value
      setWordCount(ref.current.innerText.length)
      ref?.current?.blur()
    }
  }, [value])

  useEffect(() => {
    ref.current.innerHTML = value
    setWordCount(ref.current.innerText.length)
    ref.current.blur()
    // if (!valueTrigger) {
    //   setCaret()
    // }
  }, [])

  useEffect(() => {
    if (isInputBlur) {
      ref.current.innerHTML = value
      setWordCount(ref.current.innerText.length)
      setIsOnInput(false)
    }
  }, [value])

  const colorPicker = useMemo(() => {
    return (
      <div style={{ margin: '0 0 0 4px' }}>
        <Color htmlFor='colorPicker' background={textColor}>
          <input
            id='colorPicker'
            type='color'
            value={textColor}
            onChange={(e) => {
              handleColor(e.target.value)
            }}
            name='profilesecondarycolor'
            list='profilecolorslist'
            style={{
              width: '20px',
              height: '20px',
              padding: 0,
              opacity: 0
            }}
          />
        </Color>
        <datalist id='profilecolorslist'>
          {INITIAL_COLOR.map((color, index) => {
            return <option key={index} value={color} />
          })}
        </datalist>
      </div>
    )
  }, [handleColor, textColor])

  const handleInsertHTML = useCallback((value: string) => {
    setInsertValue(value)
  }, [])

  const handleInsertValue = useCallback(() => {
    // document.getElementById('styleInput')?.focus()
    const range = document.createRange()
    // // const sel = window.getSelection()
    if (previousFocusNode)
      range.setStart(previousFocusNode, previousFocusOffset)
    range.collapse(true)
    // console.log('selection', previousSelection)
    if (previousSelection) {
      previousSelection.removeAllRanges()
      previousSelection.addRange(range)
    }
    if (!htmlValue) {
      document.execCommand(
        'insertHTML',
        true,
        '&nbsp;' + insertValue + '&nbsp;'
      )
    } else {
      document.execCommand('insertHTML', true, '' + insertValue + '&nbsp;')
    }
    setInsertValue(null)
    setIsInputFocus(true)
  }, [
    previousSelection,
    previousFocusNode,
    previousFocusOffset,
    insertValue,
    htmlValue
  ])

  const handleInsertAdvanceKeyboard = useCallback(() => {
    ref.current.focus()
    if (!isInputFocus) {
      setCaret()
      ref.current.focus()
    }
    const selection = window.getSelection()
    const currentFocusElement = selection?.focusNode
    const currentFocusOffset = selection?.focusOffset

    if (selection) setPreviousSelection(selection)
    if (currentFocusElement) setPreviousFocusNode(currentFocusElement)
    setPreviousFocusOffset(currentFocusOffset)
    setIsVisible((prev) => !prev)
  }, [isInputFocus, ref.current])

  useEffect(() => {
    // console.log('previousSelection', previousSelection)
  }, [previousSelection])

  useEffect(() => {
    if (insertValue) {
      handleInsertValue()
    }
  }, [insertValue])

  useEffect(() => {
    if (pasteText) {
      document.execCommand(
        'insertHTML',
        false,
        pasteText.substr(0, characterLength - ref.current.innerText.length)
      )
    }
  }, [pasteText])

  return (
    <InputContainer
      isInputFocus={isInputFocus}
      width={width}
      height={height}
      isLimitCharacter={isLimitCharacter}
      style={...style}
      hideOption={hideOption}
      normalInput={normalInput}
    >
      {uploadEnable && (
        <MediaUploadDrawer
          onClose={onClose}
          visible={isModalOpen}
          uploadFunction={uploadFunction}
          imageReceive={imageReceive}
          youtubeReceive={youtubeReceive}
          audioReceive={audioReceive}
        />
      )}
      <InputBox
        isInputFocus={isInputFocus}
        hideOption={hideOption}
        normalInput={normalInput}
      >
        <InputEditable
          id={styleInputId}
          contentEditable
          normalInput={normalInput}
          placeholder={placeholder}
          height={height}
          onBlur={() => {
            setIsInputBlur(true)
          }}
          onMouseDown={() => {
            const selectionText = document.getSelection()
            console.log('selectionText : ', selectionText)
            saveRef.current = selectionText
          }}
          onKeyDown={(e) => {
            handleSpecialKey(e)
          }}
          onInput={(e) => {
            onInput(e, 'input')
            setIsInputBlur(false)
            setIsOnInput(true)
          }}
          onKeyUp={() => {
            checkInputStyle()
          }}
          onClick={() => {
            checkInputStyle()
          }}
          onPaste={(e) => {
            setPasteText(e.clipboardData.getData('text'))
            const selection = window.getSelection()
            // @ts-ignore
            selection.deleteFromDocument()
            e.preventDefault()
          }}
          ref={ref}
        />

        {inputOption}
      </InputBox>

      {!normalInput && (
        <InputFooter
          isInputFocus={isInputFocus}
          hideOption={hideOption}
          height={height}
          onClick={() => ref.current.focus()}
        >
          <Space>
            {boldEnable &&

              <StyleButton isActive={isBold} onClick={() => handleBold()}>
                B
              </StyleButton>
            }
            {
              italicEnable &&
              <StyleButton isActive={isItalic} onClick={() => handleItalic()}>
                /
              </StyleButton>
            }
            {underlineEnable &&
              <StyleButton
                isActive={isUnderLine}
                onClick={() => handleUnderline()}
              >
                <u>U</u>
              </StyleButton>
            }

            {linkEnable && (
              <Box
                style={{ cursor: 'pointer', userSelect: 'none' }}
                onClick={() => {
                  selRange.current = saveSelection()
                  const selectionText = document.getSelection()
                  keepSelectedText(selectionText?.toString())

                  const currentFocusElement = selectionText?.focusNode
                  const currentFocusOffset = selectionText?.focusOffset
                  if (selectionText) setPreviousSelection(selectionText)
                  if (currentFocusElement)
                    setPreviousFocusNode(currentFocusElement)
                  setPreviousFocusOffset(currentFocusOffset)
                  openAddLinkDrawer()
                }}
              >
                <LinkIcon />
              </Box>
            )}

            {colorPickerEnable && colorPicker}
          </Space>
          <Box
            justify='space-around'
            align='center'
            style={{
              width: 'auto',
              height: '100%'
            }}
          >
            {uploadEnable && (
              <Button
                size='small'
                type='link'
                icon={media_button}
                onClick={() => showDrawer()}
              />
            )}
            {mathEnable &&

              <Button
                size='small'
                type='link'
                icon={math_button}
                // disabled={!isInputFocus}
                onClick={() => handleInsertAdvanceKeyboard()}
                style={{ margin: '0 0 0 8px' }}
              />
            }
          </Box>
        </InputFooter>
      )}
      <AddLinkDrawer
        visible={visibleAddLinkDrawer}
        defaultText={selectedTextForAddLink}
        onAddLink={({ link, text }) => {
          restoreSelection(selRange.current)
          handleAddLink({
            link: link,
            text: text
          })
          closeAddLinkDrawer()
        }}
        onDiscard={() => {
          closeAddLinkDrawer()
        }}
      />
      {isVisible && (
        <AdvanceKeyBoardComponent
          visible={isVisible}
          setIsVisible={setIsVisible}
          onInsert={(value: string) => handleInsertHTML(value)}
          title='Advanced Keyboard'
        />
      )}
    </InputContainer>
  )
})
