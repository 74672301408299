import { useState } from 'react'

const useHandleAddLink = () => {
  const [visibleAddLinkDrawer, setOpenAddLinkDrawer] = useState(false)
  const [selectedTextForAddLink, setSelectedTextForAddLink] = useState('')

  const keepSelectedText = (text) => {
    setSelectedTextForAddLink(text)
  }

  return {
    visibleAddLinkDrawer,
    closeAddLinkDrawer: () => setOpenAddLinkDrawer(false),
    openAddLinkDrawer: () => setOpenAddLinkDrawer(true),
    keepSelectedText,
    selectedTextForAddLink
  }
}
export default useHandleAddLink
