// import React, { useCallback, useState, useEffect } from 'react'
import React, { useCallback, useState } from 'react'
// import Modal from 'antd/lib/modal/Modal'
import Drawer from 'antd/lib/drawer'
import styled from 'styled-components'
import Button from 'antd/lib/button'
import Space from 'antd/lib/space'
import Row from 'antd/lib/row'
// import Input from 'antd/lib/input'
// import Typography from 'antd/lib/typography'
import { Menu, Dropdown, Image } from 'antd'
// import SearchOutlined from '@ant-design/icons/SearchOutlined'

import { DownOutlined } from '@ant-design/icons'
import { mathLatexList } from './mathLatexList'

// import { addStyles, EditableMathField, StaticMathField } from 'react-mathquill'
import { addStyles, EditableMathField } from 'react-mathquill'
import Box from 'designSystem/Box'
import BaseButton from 'designSystem/BaseButton'
addStyles()

interface AdvanceKeyBoardProps {
  visible: boolean
  setIsVisible: any
  onInsert?: any
  onCancel?: any
  bodyStyle?: any
  style?: any
  title?: string | React.ReactNode
  placeholder?: string
  latexValue?: string
}

// const BaseModal = styled(Modal)`
//   border-radius: 6px;
//   .ant-modal-body {
//     padding: 0px !important;
//     overflow-y: scroll;
//   }
// `
const BaseDrawer = styled(Drawer)`
  display: flex;

  .ant-drawer-body {
    padding: 0;
    position: relative;
  }
  .ant-card-bordered {
    border: none;
  }
  .ant-card-head {
    border-bottom: none;
    font-weight: normal;
  }
  .ant-card-body {
    padding: 0px 24px;
  }

  .ant-drawer-content-wrapper {
    min-width: 500px;
  }

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background: none;
  }
  ::-webkit-scrollbar-track {
    background: none;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 16px;
    background: #7a7b7d;
  }
`

const InputAreaBox = styled(Box)`
  padding: 24px 16px;
`

const InputBox = styled(Box)`
  overflow: visible;
  min-height: 96px;
  outline: none;
  border: 1px solid #e5e7ea;
  border-radius: 6px;
  padding: 1em;
  .mq-editable-field,
  .mq-math-mode .mq-editable-field {
    border: none;
    height: 100%;
    min-height: 96px;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }
  .mq-root-block {
    width: auto;
  }
  .mq-root-block,
  .mq-math-mode .mq-root-block {
    padding: 8px 2px 2px;
  }

  .mq-editable-field.mq-focused,
  .mq-math-mode .mq-editable-field.mq-focused {
    box-shadow: none;
    border-color: none;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }
`
const TitleBox = styled(Box)`
  padding: 2.5% 16px;
  border-bottom: 1px solid;
  border-color: #eceef0;
`

const AdvanceKeyboardContentBox = styled(Box)``

const HeaderRow = styled(Row)`
  padding: 10px 16px;
  border: 1px solid;
  border-color: #eceef0;
  background-color: var(--silver-100);
`
const BodyBox = styled(Box)`
  /* height: 300px; */
  background-color: var(--silver-200);
  overflow-y: scroll;
  padding: 16px;
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: none;
  }
  ::-webkit-scrollbar-track {
    background: none;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 16px;
    background: #7a7b7d;
  }
`
const CategoryBox = styled(Box)`
  border-radius: 6px;
  border: '1px solid #E5E7EA';
  height: 100%;
`
// const InputSearch = styled(Input)``
// const SearchOutlinedStyled = styled(SearchOutlined)`
//   font-size: 18px;
//   opacity: 0.5;
// `

const MathButtonStyle = styled(Box)`
  width: 56px;
  height: 56px;
  background: #ffffff;
  border: 1px solid #bcbec1;
  box-sizing: border-box;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
`

const AdvanceKeyBoardContent = ({ mathField }) => {
  // const [searchText, setSearchText] = useState('')
  const [selectedCategory, setSelectedCategory] = useState('Most Popular')
  // const [mathButtonData, setMathButtonData] = useState(mathLatexList)
  const [mathButtonData] = useState(mathLatexList)

  const handleAddLatex = useCallback(
    (latexString: string | Array<string>) => {
      // mathField.focus()
      mathField.cmd(latexString)
      // mathField.focus()
    },
    [mathField]
  )
  const handleAddLatexWithInsertion = useCallback(
    (latexString: string | Array<string>) => {
      handleAddLatex(latexString)
      mathField.keystroke('Down')
    },
    [mathField]
  )
  const handleAddLatexWithSequence = useCallback(
    (latexSequence: Array<string>) => {
      // console.log('latexSequence', latexSequence)
      latexSequence.forEach((latexString) => {
        mathField.write(latexString)
        mathField.focus()
      })
    },
    [mathField]
  )
  // const handleAddLatexWithWrite = useCallback(
  //   (latexString: string | Array<string>) => {
  //     mathField.write(latexString)
  //   },
  //   [mathField]
  // )
  const handleAddLatexWithWriteInsertion = useCallback(
    (latexString: string | Array<string>) => {
      mathField.write(latexString)
      mathField.keystroke('Down')
    },
    [mathField]
  )
  const menu = (
    <Menu onClick={() => {}}>
      <Menu.Item
        key='Popular'
        onClick={() => setSelectedCategory('Most Popular')}
      >
        Most Popular
      </Menu.Item>
      {/* <Menu.ItemGroup title='Bookmark'>
        <Menu.Item key='1'>Frequently used</Menu.Item>
        <Menu.Item key='2'>Most Popular</Menu.Item>
      </Menu.ItemGroup>
      <Menu.ItemGroup title='Category'>
        <Menu.Item key='1'>Mathematics Symbols</Menu.Item>
        <Menu.Item key='2'>Greek</Menu.Item>
        <Menu.Item key='3'>Equations</Menu.Item>
        <Menu.Item key='4'>Arrows</Menu.Item>
      </Menu.ItemGroup> */}
    </Menu>
  )
  const MathButton = ({ data, onclick }) => (
    <MathButtonStyle key={data.name.en} onClick={onclick}>
      {data.icon && (
        <Image
          width={50}
          height={50}
          src={data.icon}
          alt={data.name.en}
          preview={false}
        />
      )}
    </MathButtonStyle>
  )
  const renderMathLatexButton = (data: any) => {
    const method = data?.insertMethod

    switch (method) {
      // case 'normal':
      //   return (
      //     <Button key={data.name.en} onClick={() => handleAddLatex(data.latex)}>
      //       {data.name.en}
      //     </Button>
      //   )
      // case 'insertPattern':
      //   return (
      //     <Button
      //       key={data.name.en}
      //       onClick={() => handleAddLatexWithInsertion(data.latex)}
      //     >
      //       {data.name.en}
      //     </Button>
      //   )
      // case 'sequence':
      //   return (
      //     <Button
      //       key={data.name.en}
      //       onClick={() => handleAddLatexWithSequence(data.latex)}
      //     >
      //       {data.name.en}
      //     </Button>
      //   )

      // case 'write':
      //   return (
      //     <Button
      //       key={data.name.en}
      //       onClick={() => handleAddLatexWithWriteInsertion(data.latex)}
      //     >
      //       {data.name.en}
      //     </Button>
      //   )
      case 'normal':
        return MathButton({ data, onclick: () => handleAddLatex(data.latex) })
      case 'insertPattern':
        return MathButton({
          data,
          onclick: () => handleAddLatexWithInsertion(data.latex)
        })
      case 'sequence':
        return MathButton({
          data,
          onclick: () => handleAddLatexWithSequence(data.latex)
        })
      case 'write':
        return MathButton({
          data,
          onclick: () => handleAddLatexWithWriteInsertion(data.latex)
        })
      default:
        return null
    }
  }
  // const handleSearchMathButton = useCallback(() => {
  //   setMathButtonData(() => {

  //     // return newData.length !== 0 ? newData : mathLatexList
  //     const newData = mathLatexList.filter((data) =>
  //       data.keyword.some((keyword) => keyword.includes(searchText))
  //     )
  //     return newData.length !== 0 ? newData : mathLatexList
  //   })
  // }, [mathLatexList, searchText])

  // useEffect(() => {
  //   if (searchText) {
  //     // console.log('searchText', searchText)
  //     handleSearchMathButton()
  //   }
  // }, [searchText])
  return (
    <AdvanceKeyboardContentBox direction='column'>
      <HeaderRow justify='space-between'>
        <Dropdown overlay={menu}>
          <Button style={{ padding: '0px', borderRadius: '6px' }}>
            <CategoryBox align='center' direction='row'>
              <Box
                direction='column'
                style={{ padding: '0px 6px', color: '#242938' }}
              >
                {selectedCategory}
              </Box>
              <Box
                direction='row'
                align='center'
                style={{
                  height: '100%',
                  padding: '0px 6px',
                  borderLeft: ' 1px solid #E5E7EA'
                }}
              >
                <DownOutlined />
              </Box>
            </CategoryBox>
          </Button>
        </Dropdown>
        {/* <Box>
          <InputSearch
            placeholder='Search'
            prefix={<SearchOutlinedStyled />}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onPressEnter={() => handleSearchMathButton()}
          />
        </Box> */}
      </HeaderRow>
      <BodyBox direction='column'>
        {/* <Row style={{ marginBottom: '8px' }}>
          <Typography.Text strong>Recently Used</Typography.Text>
        </Row> */}
        <Space size={[12, 16]} wrap>
          {mathButtonData?.map((data) => {
            // console.log('data', data)
            return renderMathLatexButton(data)
          })}
        </Space>
      </BodyBox>
    </AdvanceKeyboardContentBox>
  )
}

// export const StaticMathBox = ({ latexString }) => {
//   return <StaticMathField>{latexString}</StaticMathField>
// }
export const AdvanceKeyBoardComponent = ({
  visible,
  setIsVisible,
  onInsert,
  title
}: // placeholder
AdvanceKeyBoardProps) => {
  const [hasValue] = useState<boolean>(true)
  const [latex, setLatex] = useState('')
  const [latextId] = useState<string>(`latex-${Date.now()}`)

  const [mathFieldData, setMathFieldData] = useState<any>(null)

  // const handleGetHTML = useCallback(() => {
  //   // console.log('mathFieldData', mathFieldData)
  //   const template: any = document.getElementById('AdvanceKeyboardEditables')
  //   // console.log(
  //     typeof document.getElementById('AdvanceKeyboardEditables')?.innerHTML
  //   )
  //   const s = new XMLSerializer()
  //   const str = s.serializeToString(template)
  //   onInsert(str === '' ? '' : str)
  //   setIsVisible(false)
  // }, [mathFieldData])

  // useEffect(() => {
  //   setLatexId(`latex-${Date.now()}`)
  // }, [])

  // const handleGetMathHTML = useCallback(() => {
  //   const template: any = document.getElementById(latextId || '')
  //   template.removeChild(template.childNodes[0])
  //   // console.log(template)
  //   const s = new XMLSerializer()
  //   const str = s.serializeToString(template)

  //   // console.log('str', str)
  //   onInsert(str === '' ? '' : str)
  //   setIsVisible(false)
  // }, [onInsert, latextId])
  const handleGetMathHTML = useCallback(() => {
    console.log(latextId)
    const template: any = document.getElementById(latextId)
    console.log('template', template)
    console.log('template', template.childNodes)
    if (template.childNodes.length > 1)
      template.removeChild(template.childNodes[0])

    const s = new XMLSerializer()
    const str = s.serializeToString(template)

    // console.log('str', str)
    onInsert(str === '' ? '' : str)
    setIsVisible(false)
  }, [onInsert])

  // useEffect(() => {
  //   if (latex) {
  //     // console.log('latex', latex)
  //   }
  // })

  return (
    <BaseDrawer
      destroyOnClose
      closable={false}
      // title={
      //   <Box direction='row'>
      //     <Box style={{ width: '100%' }}>{title}</Box>
      //     <Space size='small'>
      //       <BaseButton
      //         type='text'
      //         style={{ color: 'var(--blue-400)' }}
      //         onClick={() => setIsVisible(false)}
      //       >
      //         Cancel
      //       </BaseButton>
      //       <BaseButton
      //         disabled={!hasValue}
      //         type='primary'
      //         onClick={() => handleGetMathHTML()}
      //       >
      //         Insert
      //       </BaseButton>
      //     </Space>
      //   </Box>
      // }
      visible={visible}
      onClose={() => setIsVisible(false)}
      bodyStyle={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        borderRadius: '6px 0px 0px 6px'
      }}
    >
      <TitleBox direction='row'>
        <Box
          align='center'
          style={{
            width: '100%',
            fontWeight: 500,
            fontSize: '18px',
            color: '#80879B'
          }}
        >
          {title}
        </Box>
        <Space size='small'>
          <BaseButton
            type='text'
            style={{ color: 'var(--blue-400)' }}
            onClick={() => setIsVisible(false)}
          >
            Discard
          </BaseButton>
          <BaseButton
            disabled={!hasValue}
            type='primary'
            onClick={() => handleGetMathHTML()}
          >
            Confirm
          </BaseButton>
        </Space>
      </TitleBox>
      <InputAreaBox direction='column'>
        {latextId && (
          <InputBox direction='column'>
            <EditableMathField
              config={{
                spaceBehavesLikeTab: true,
                leftRightIntoCmdGoes: 'up',
                restrictMismatchedBrackets: true,
                sumStartsWithNEquals: true,
                supSubsRequireOperand: true,
                charsThatBreakOutOfSupSub: '+-=<>',
                autoSubscriptNumerals: true,
                autoCommands: 'pi theta sqrt sum',
                autoOperatorNames: 'sin cos tan lim log ln',
                maxDepth: 4
              }}
              latex={latex}
              onChange={(mathField) => {
                // console.log('mathField', mathField)
                setMathFieldData(mathField)
                mathField.focus()
                setLatex(mathField.latex())
              }}
              mathquillDidMount={(mathField) => {
                setMathFieldData(mathField)
              }}
              // id='AdvanceKeyboardEditables'
              id={latextId}
            />
          </InputBox>
        )}
      </InputAreaBox>

      {/* <div> */}
      {/* <div style={{ display: 'none' }}>
        {latextId && (
          <StaticMathField id={latextId} className='math-symbol'>
            {latex}
          </StaticMathField>
        )}
      </div> */}

      <AdvanceKeyBoardContent mathField={mathFieldData} />
    </BaseDrawer>
  )
}
