import { useRef } from 'react'

const useSaveSelection = () => {
  const saveRef = useRef<any>()
  const selRange = useRef<any>()

  function saveSelection() {
    if (window.getSelection) {
      saveRef.current = window.getSelection()
      if (saveRef.current.getRangeAt && saveRef.current.rangeCount) {
        return saveRef.current.getRangeAt(0)
      }
      //@ts-ignore
    } else if (document.selection && document.selection.createRange) {
      //@ts-ignore
      return document.selection.createRange()
    }
    return null
  }

  function restoreSelection(range) {
    if (range) {
      if (window.getSelection) {
        saveRef.current = window.getSelection()
        saveRef.current.removeAllRanges()
        saveRef.current.addRange(range)
        //@ts-ignore
      } else if (document.selection && range.select) {
        range.select()
      }
    }
  }
  return {
    saveSelection,
    restoreSelection,
    saveRef,
    selRange
  }
}

export default useSaveSelection
