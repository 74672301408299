import absolute from 'icon/absolute.svg'
// import backslash from 'icon/backslash.svg'
import ceil from 'icon/ceil.svg'
import devidebyd from 'icon/devidebyd.svg'
import exists from 'icon/exists.svg'
import expo from 'icon/expo.svg'
import floor from 'icon/floor.svg'
import forall from 'icon/forall.svg'
import fraction from 'icon/fraction.svg'
import inicon from 'icon/in.svg'
import infinity from 'icon/infinity.svg'
import integrate from 'icon/integrate.svg'
import intersect from 'icon/intersect.svg'
import limit from 'icon/limit.svg'
import log10 from 'icon/log10.svg'
import logn from 'icon/logn.svg'
import lon from 'icon/lon.svg'
import notin from 'icon/notin.svg'
import notsubset from 'icon/notsubset.svg'
import notsubsetequal from 'icon/notsubsetequal.svg'
import nthroot from 'icon/nthroot.svg'
// import oslash from 'icon/oslash.svg'
import overleftarrow from 'icon/overleftarrow.svg'
import overline from 'icon/overline.svg'
import overrightarrow from 'icon/overrightarrow.svg'
import pi from 'icon/pi.svg'
import power from 'icon/power.svg'
import power2 from 'icon/power2.svg'
import prod from 'icon/prod.svg'
import root from 'icon/root.svg'
import subset from 'icon/subset.svg'
import subsetequal from 'icon/subsetequal.svg'
import sum from 'icon/sum.svg'
import subscript from 'icon/subscript.svg'
import union from 'icon/union.svg'
import vee from 'icon/vee.svg'
import wedge from 'icon/wedge.svg'

import plus from 'icon/plus.svg'
import minus from 'icon/minus.svg'
import multiply from 'icon/multiply.svg'
import devide from 'icon/devide.svg'
import equal from 'icon/equal.svg'
import notequal from 'icon/notequal.svg'
import percentage from 'icon/percentage.svg'

type insertMethod =
  | 'normal'
  | 'insertPattern'
  | 'sequence'
  | 'write'
  | 'writePattern'

export type mathLatexDataType = {
  name: {
    en: string
    th: string
  }
  keyword: Array<string>
  latex: string | Array<string>
  category: Array<string>
  subCategory: string
  insertMethod: insertMethod
  keyStrokeCommand?: Array<string>
  icon?: string
  symbol?: string
}

type mathLatexListType = Array<mathLatexDataType>

export const mathLatexList: mathLatexListType = [
  {
    name: { en: 'plus', th: 'บวก' },
    keyword: ['plus', 'addition', 'บวก'],
    latex: '+',
    category: ['math'],
    subCategory: 'basic',
    insertMethod: 'normal',
    icon: plus
  },
  {
    name: { en: 'minus', th: 'ลบ' },
    keyword: ['minus', 'ลบ'],
    latex: '-',
    category: ['math'],
    subCategory: 'basic',
    insertMethod: 'normal',
    icon: minus
  },
  // {
  //   name: { en: 'dot', th: 'จุด' },
  //   keyword: ['dot', 'คูณ', 'จุด'],
  //   latex: '⋅',
  //   category: ['math'],
  //   subCategory: 'basic',
  //   insertMethod: 'write'
  // },
  {
    name: { en: 'multiply', th: 'คูณ' },
    keyword: ['multiply', 'time', 'คูณ', 'เท่า'],
    latex: '\\times',
    category: ['math'],
    subCategory: 'basic',
    insertMethod: 'normal',
    icon: multiply
  },
  {
    name: { en: 'devide', th: 'หาร' },
    keyword: ['devide', 'ส่วน', 'หาร'],
    latex: '\\div',
    category: ['math'],
    subCategory: 'basic',
    insertMethod: 'normal',
    icon: devide
  },
  {
    name: { en: 'equal', th: 'เท่ากับ' },
    keyword: ['equal', 'เท่ากับ'],
    latex: '=',
    category: ['math'],
    subCategory: 'basic',
    insertMethod: 'write',
    icon: equal
  },
  {
    name: { en: 'not equal', th: 'ไม่เท่ากับ' },
    keyword: ['not equal', 'ไม่เท่ากับ'],
    latex: '\\neq',
    category: ['math'],
    subCategory: 'basic',
    insertMethod: 'normal',
    icon: notequal
  },
  {
    name: { en: 'percent', th: 'ร้อยละ' },
    keyword: ['percent', 'เปอเซ็นต์', 'ร้อยละ'],
    latex: '%',
    category: ['math'],
    subCategory: 'basic',
    insertMethod: 'normal',
    icon: percentage
  },
  {
    name: { en: 'fraction', th: 'เศษส่วน' },
    keyword: ['fraction', 'เศษส่วน'],
    latex: '\\frac',
    category: ['math'],
    subCategory: 'basic',
    insertMethod: 'normal',
    icon: fraction
  },
  {
    name: { en: 'power2', th: 'ยกกำลังสอง' },
    keyword: ['power/', 'ยกกำลังสอง'],
    latex: '^{2}',
    category: ['math'],
    subCategory: 'pattern',
    insertMethod: 'write',
    icon: power2
  },
  {
    name: { en: 'power', th: 'ยกกำลัง' },
    keyword: ['power', 'ยกกำลัง'],
    latex: '^',
    category: ['math'],
    subCategory: 'pattern',
    insertMethod: 'normal',
    icon: power
  },
  {
    name: { en: 'subscript', th: 'ยกกำลัง' },
    keyword: ['subscript', 'ยกกำลัง'],
    latex: '_',
    category: ['math'],
    subCategory: 'pattern',
    insertMethod: 'normal',
    icon: subscript
  },
  {
    name: { en: 'absolute', th: 'ค่าสัมบูรณ์' },
    keyword: ['absolute', 'ค่าสัมบูรณ์', 'abs'],
    latex: '|',
    category: ['math'],
    subCategory: 'basic',
    insertMethod: 'normal',
    icon: absolute
  },
  {
    name: { en: 'square root', th: 'รากที่สอง' },
    keyword: ['square root', 'root', 'รากที่สอง', 'ราก'],
    latex: '\\sqrt',
    category: ['math'],
    subCategory: 'basic',
    insertMethod: 'insertPattern',
    icon: root
  },
  {
    name: { en: 'nth root', th: 'รากที่ n' },
    keyword: ['nth root', 'root', 'รากที่ n', 'ราก'],
    latex: '\\sqrt[]{}',
    category: ['math'],
    subCategory: 'basic',
    insertMethod: 'write',
    icon: nthroot
  },
  {
    name: { en: 'overline', th: '' },
    keyword: ['overline', ''],
    latex: '\\overline',
    category: ['math'],
    subCategory: 'pattern',
    insertMethod: 'normal',
    icon: overline
  },
  {
    name: { en: 'overleftarrow', th: '' },
    keyword: ['overleftarrow', ''],
    latex: '\\overleftarrow',
    category: ['math'],
    subCategory: 'pattern',
    insertMethod: 'normal',
    icon: overleftarrow
  },
  {
    name: { en: 'overrightarrow', th: '' },
    keyword: ['overrightarrow', ''],
    latex: '\\overrightarrow',
    category: ['math'],
    subCategory: 'pattern',
    insertMethod: 'normal',
    icon: overrightarrow
  },
  {
    name: { en: 'limit', th: 'ลิมิต' },
    keyword: ['limit', 'ลิมิต'],
    latex: ['lim', '_{\\to}'],
    category: ['math'],
    subCategory: 'basic',
    insertMethod: 'sequence',
    icon: limit
  },
  {
    name: { en: 'summation', th: 'อนุกรม' },
    keyword: ['summation', 'ผลรวม', 'sum'],
    latex: '\\sum',
    category: ['math'],
    subCategory: 'basic',
    insertMethod: 'normal',
    icon: sum
  },
  {
    name: { en: 'prod', th: '' },
    keyword: ['prod', '', 'sum'],
    latex: '\\prod',
    category: ['math'],
    subCategory: 'basic',
    insertMethod: 'normal',
    icon: prod
  },
  {
    name: { en: 'ceil', th: '' },
    keyword: ['ceil'],
    latex: ['\\lceil', '\\rceil'],
    category: ['math'],
    subCategory: 'basic',
    insertMethod: 'sequence',
    icon: ceil
  },
  {
    name: { en: 'floor', th: '' },
    keyword: ['floor'],
    latex: ['\\lfloor', '\\rfloor'],
    category: ['math'],
    subCategory: 'basic',
    insertMethod: 'sequence',
    icon: floor
  },
  {
    name: { en: 'lon', th: '' },
    keyword: ['lon'],
    latex: 'ln',
    category: ['math'],
    subCategory: 'basic',
    insertMethod: 'normal',
    icon: lon
  },
  {
    name: { en: 'log', th: 'ลอการิทึม' },
    keyword: ['log', 'ลอก', 'logarithm'],
    latex: ['log', '_{10}'],
    category: ['math'],
    subCategory: 'basic',
    insertMethod: 'sequence',
    icon: log10
  },
  {
    name: { en: 'log', th: 'ลอการิทึม' },
    keyword: ['log', 'ลอก', 'logarithm'],
    latex: ['log', '_{}'],
    category: ['math'],
    subCategory: 'basic',
    insertMethod: 'sequence',
    icon: logn
  },
  {
    name: { en: 'integral', th: 'ปฏิยานุพันธ์' },
    keyword: ['integral', 'ปฏิยานุพันธ์', 'อินทริเกรต'],
    latex: '\\int',
    category: ['math'],
    subCategory: 'basic',
    insertMethod: 'normal',
    icon: integrate
  },

  {
    name: { en: 'devide by d', th: '' },
    keyword: ['devide by d', '', ''],
    latex: '\\frac{d}{d}',
    category: ['math'],
    subCategory: 'basic',
    insertMethod: 'write',
    icon: devidebyd
  },
  {
    name: { en: 'forall', th: '' },
    keyword: ['forall'],
    latex: '\\forall',
    category: ['math'],
    subCategory: 'basic',
    insertMethod: 'normal',
    icon: forall
  },
  {
    name: { en: 'exists', th: '' },
    keyword: ['exists'],
    latex: '\\exists',
    category: ['math'],
    subCategory: 'basic',
    insertMethod: 'normal',
    icon: exists
  },
  {
    name: { en: 'in', th: '' },
    keyword: ['in'],
    latex: '\\in',
    category: ['math'],
    subCategory: 'basic',
    insertMethod: 'normal',
    icon: inicon
  },
  {
    name: { en: 'notin', th: '' },
    keyword: ['notin'],
    latex: '\\notin',
    category: ['math'],
    subCategory: 'basic',
    insertMethod: 'normal',
    icon: notin
  },
  {
    name: { en: 'vee', th: '' },
    keyword: ['vee'],
    latex: '\\vee',
    category: ['math'],
    subCategory: 'basic',
    insertMethod: 'normal',
    icon: vee
  },
  {
    name: { en: 'wedge', th: '' },
    keyword: ['wedge'],
    latex: '\\wedge',
    category: ['math'],
    subCategory: 'basic',
    insertMethod: 'normal',
    icon: wedge
  },
  {
    name: { en: 'subset', th: '' },
    keyword: ['subset'],
    latex: '\\subset',
    category: ['math'],
    subCategory: 'basic',
    insertMethod: 'normal',
    icon: subset
  },
  {
    name: { en: 'notsubset', th: '' },
    keyword: ['notsubset'],
    latex: '\\nsubset',
    category: ['math'],
    subCategory: 'basic',
    insertMethod: 'normal',
    icon: notsubset
  },
  {
    name: { en: 'intersect', th: '' },
    keyword: ['intersect'],
    latex: '\\cap',
    category: ['math'],
    subCategory: 'basic',
    insertMethod: 'normal',
    icon: intersect
  },
  {
    name: { en: 'union', th: '' },
    keyword: ['union'],
    latex: '\\cup',
    category: ['math'],
    subCategory: 'basic',
    insertMethod: 'normal',
    icon: union
  },
  {
    name: { en: 'subsetequal', th: '' },
    keyword: ['subset equal'],
    latex: '\\subseteq',
    category: ['math'],
    subCategory: 'basic',
    insertMethod: 'normal',
    icon: subsetequal
  },
  {
    name: { en: 'notsubsetequal', th: '' },
    keyword: ['notsubsetequal'],
    latex: '\\nsubseteq',
    category: ['math'],
    subCategory: 'basic',
    insertMethod: 'normal',
    icon: notsubsetequal
  },
  {
    name: { en: 'pi', th: '' },
    keyword: ['pi'],
    latex: '\\pi',
    category: ['math'],
    subCategory: 'basic',
    insertMethod: 'normal',
    icon: pi
  },
  {
    name: { en: 'expo', th: '' },
    keyword: ['e'],
    latex: 'e',
    category: ['math'],
    subCategory: 'basic',
    insertMethod: 'normal',
    icon: expo
  },
  {
    name: { en: 'infinity', th: 'ไม่จำกัด' },
    keyword: ['infinity', 'ไม่จำกัด'],
    latex: '\\infty',
    category: ['math'],
    subCategory: 'basic',
    insertMethod: 'normal',
    icon: infinity
  }

  // {
  //   name: { en: 'percent', th: 'ร้อยละ' },
  //   keyword: ['percent', 'เปอเซ็นต์', 'ร้อยละ'],
  //   latex: '%',
  //   category: ['math'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: { en: 'plusminus', th: 'บวกลบ' },
  //   keyword: ['plusminus', 'บวกลบ'],
  //   latex: '\\pm',
  //   category: ['math'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: { en: 'not equal', th: 'ไม่เท่ากับ' },
  //   keyword: ['not equal', 'ไม่เท่ากับ'],
  //   latex: '\\pm',
  //   category: ['math'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: { en: 'less than', th: 'น้อยกว่า' },
  //   keyword: ['less than', 'น้อยกว่า'],
  //   latex: '\\lt',
  //   category: ['math'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: { en: 'greater than', th: 'มากกว่า' },
  //   keyword: ['greater', 'มากกว่า', 'greater than'],
  //   latex: '\\gt',
  //   category: ['math'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: { en: 'less than equal', th: 'น้่อยกว่าเท่ากับ' },
  //   keyword: ['less than equal', 'น้่อยกว่าเท่ากับ', 'less than'],
  //   latex: '\\leq',
  //   category: ['math'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: { en: 'greater than equal', th: 'มากกว่าเท่ากับ' },
  //   keyword: ['greater than equal', 'มากกว่าเท่ากับ', 'greater than'],
  //   latex: '\\geq',
  //   category: ['math'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: { en: 'approximate', th: 'ประมาณ' },
  //   keyword: ['approximate', 'ประมาณ'],
  //   latex: '\\approx',
  //   category: ['math'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: { en: 'equivalent', th: 'สมมูล' },
  //   keyword: ['equivalent', 'สมมูล'],
  //   latex: '\\equiv',
  //   category: ['math'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: { en: 'angle', th: 'มุม' },
  //   keyword: ['angle', 'มุม'],
  //   latex: '\\angle',
  //   category: ['math'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: { en: 'degree', th: 'องศา' },
  //   keyword: ['degree', 'องศา'],
  //   latex: '\\degree',
  //   category: ['math'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: {
  //     en: 'circle',
  //     th: 'วงกลม'
  //   },
  //   keyword: ['circle', 'วงกลม'],
  //   latex: '\\circ',
  //   category: ['math'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: {
  //     en: 'leftarrow',
  //     th: 'ลูกศรซ้าย'
  //   },
  //   keyword: ['leftarrow', 'ลูกศร', 'ลูกศรซ้าย'],
  //   latex: '\\leftarrow',
  //   category: ['math'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: {
  //     en: 'Leftarrow',
  //     th: 'ลูกศรซ้าย'
  //   },
  //   keyword: ['Leftarrow', 'ลูกศร', 'ลูกศรซ้าย'],
  //   latex: '\\Leftarrow',
  //   category: ['math'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: {
  //     en: 'rightarrow',
  //     th: 'ลูกศรขวา'
  //   },
  //   keyword: ['rightarrow', 'ลูกศร', 'ลูกศรซ้าย'],
  //   latex: '\\rightarrow',
  //   category: ['math'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: {
  //     en: 'Rightarrow',
  //     th: 'ลูกศรขวา'
  //   },
  //   keyword: ['Rightarrow', 'ลูกศร', 'ลูกศรขวา'],
  //   latex: '\\Rightarrow',
  //   category: ['math'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: {
  //     en: 'Leftrightarrow',
  //     th: 'ลูกศรซ้ายขวา'
  //   },
  //   keyword: ['Leftrightarrow', 'ลูกศร', 'ลูกศรขวา'],
  //   latex: '\\Leftrightarrow',
  //   category: ['math'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },

  // greek
  // {
  //   name: {
  //     en: 'alpha',
  //     th: ''
  //   },
  //   keyword: ['alpha', '', ''],
  //   latex: '\\alpha',
  //   category: ['greek'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: {
  //     en: 'beta',
  //     th: ''
  //   },
  //   keyword: ['beta'],
  //   latex: '\\beta',
  //   category: ['greek'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: {
  //     en: 'chi',
  //     th: ''
  //   },
  //   keyword: ['chi'],
  //   latex: '\\chi',
  //   category: ['greek'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: {
  //     en: 'delta',
  //     th: ''
  //   },
  //   keyword: ['delta'],
  //   latex: '\\delta',
  //   category: ['greek'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: {
  //     en: 'epsilon',
  //     th: ''
  //   },
  //   keyword: ['epsilon'],
  //   latex: '\\epsilon',
  //   category: ['greek'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: {
  //     en: 'eta',
  //     th: ''
  //   },
  //   keyword: ['eta'],
  //   latex: '\\eta',
  //   category: ['greek'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: {
  //     en: 'gamma',
  //     th: ''
  //   },
  //   keyword: ['gamma'],
  //   latex: '\\gamma',
  //   category: ['greek'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: {
  //     en: 'iota',
  //     th: ''
  //   },
  //   keyword: ['iota'],
  //   latex: '\\iota',
  //   category: ['greek'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: {
  //     en: 'kappa',
  //     th: ''
  //   },
  //   keyword: ['kappa'],
  //   latex: '\\kappa',
  //   category: ['greek'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: {
  //     en: 'lambda',
  //     th: ''
  //   },
  //   keyword: ['lambda'],
  //   latex: '\\lambda',
  //   category: ['greek'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: {
  //     en: 'mu',
  //     th: ''
  //   },
  //   keyword: ['mu'],
  //   latex: '\\mu',
  //   category: ['greek'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: {
  //     en: 'nu',
  //     th: ''
  //   },
  //   keyword: ['nu'],
  //   latex: '\\nu',
  //   category: ['greek'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: {
  //     en: 'omega',
  //     th: ''
  //   },
  //   keyword: ['omega'],
  //   latex: '\\omega',
  //   category: ['greek'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: {
  //     en: 'phi',
  //     th: ''
  //   },
  //   keyword: ['phi'],
  //   latex: '\\phi',
  //   category: ['greek'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: {
  //     en: 'pi',
  //     th: ''
  //   },
  //   keyword: ['pi'],
  //   latex: '\\pi',
  //   category: ['greek'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: {
  //     en: 'rho',
  //     th: ''
  //   },
  //   keyword: ['rho'],
  //   latex: '\\rho',
  //   category: ['greek'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: {
  //     en: 'sigma',
  //     th: ''
  //   },
  //   keyword: ['sigma'],
  //   latex: '\\sigma',
  //   category: ['greek'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: {
  //     en: 'tau',
  //     th: ''
  //   },
  //   keyword: ['tau'],
  //   latex: '\\tau',
  //   category: ['greek'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: {
  //     en: 'theta',
  //     th: ''
  //   },
  //   keyword: ['theta'],
  //   latex: '\\theta',
  //   category: ['greek'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: {
  //     en: 'upsilon',
  //     th: ''
  //   },
  //   keyword: ['upsilon'],
  //   latex: '\\upsilon',
  //   category: ['greek'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: {
  //     en: 'xi',
  //     th: ''
  //   },
  //   keyword: ['xi'],
  //   latex: '\\xi',
  //   category: ['greek'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: {
  //     en: 'zeta',
  //     th: ''
  //   },
  //   keyword: ['zeta'],
  //   latex: '\\zeta',
  //   category: ['greek'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },

  // {
  //   name: {
  //     en: 'digamma',
  //     th: ''
  //   },
  //   keyword: ['digamma'],
  //   latex: '\\digamma',
  //   category: ['greek'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: {
  //     en: 'varepsilon',
  //     th: ''
  //   },
  //   keyword: ['varepsilon'],
  //   latex: '\\varepsilon',
  //   category: ['greek'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: {
  //     en: 'varkappa',
  //     th: ''
  //   },
  //   keyword: ['varkappa'],
  //   latex: '\\varkappa',
  //   category: ['greek'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: {
  //     en: 'varphi',
  //     th: ''
  //   },
  //   keyword: ['varphi'],
  //   latex: '\\varphi',
  //   category: ['greek'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: {
  //     en: 'varpi',
  //     th: ''
  //   },
  //   keyword: ['varpi'],
  //   latex: '\\varpi',
  //   category: ['greek'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: {
  //     en: 'varrho',
  //     th: ''
  //   },
  //   keyword: ['varrho'],
  //   latex: '\\varrho',
  //   category: ['greek'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: {
  //     en: 'varsigma',
  //     th: ''
  //   },
  //   keyword: ['varsigma'],
  //   latex: '\\varsigma',
  //   category: ['greek'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: {
  //     en: 'vartheta',
  //     th: ''
  //   },
  //   keyword: ['vartheta'],
  //   latex: '\\vartheta',
  //   category: ['greek'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: {
  //     en: 'Delta',
  //     th: ''
  //   },
  //   keyword: ['Delta'],
  //   latex: '\\Delta',
  //   category: ['greek'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: {
  //     en: 'Lambda',
  //     th: ''
  //   },
  //   keyword: ['Lambda'],
  //   latex: '\\Lambda',
  //   category: ['greek'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: {
  //     en: 'Omega',
  //     th: ''
  //   },
  //   keyword: ['Omega'],
  //   latex: '\\Omega',
  //   category: ['greek'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: {
  //     en: 'Phi',
  //     th: ''
  //   },
  //   keyword: ['Phi'],
  //   latex: '\\Phi',
  //   category: ['greek'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: {
  //     en: 'Pi',
  //     th: ''
  //   },
  //   keyword: ['Pi'],
  //   latex: '\\Pi',
  //   category: ['greek'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: {
  //     en: 'Psi',
  //     th: ''
  //   },
  //   keyword: ['Psi'],
  //   latex: '\\Psi',
  //   category: ['greek'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: {
  //     en: 'Sigma',
  //     th: ''
  //   },
  //   keyword: ['Sigma'],
  //   latex: '\\Sigma',
  //   category: ['greek'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: {
  //     en: 'Theta',
  //     th: ''
  //   },
  //   keyword: ['Theta'],
  //   latex: '\\Theta',
  //   category: ['greek'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: {
  //     en: 'Upsilon',
  //     th: ''
  //   },
  //   keyword: ['Upsilon'],
  //   latex: '\\Upsilon',
  //   category: ['greek'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // },
  // {
  //   name: {
  //     en: 'Xi',
  //     th: ''
  //   },
  //   keyword: ['Xi'],
  //   latex: '\\Xi',
  //   category: ['greek'],
  //   subCategory: 'basic',
  //   insertMethod: 'normal'
  // }
]
// export default mathLatexList

// \alpha κ \kappa ψ \psi z \digamma ∆ \Delta Θ \Theta
// β \beta λ \lambda ρ \rho ε \varepsilon Γ \Gamma Υ \Upsilon
// χ \chi µ \mu σ \sigma κ \varkappa Λ \Lambda Ξ \Xi
// δ \delta ν \nu τ \tau ϕ \varphi Ω \Omega
//  \epsilon o o θ \theta $ \varpi Φ \Phi ℵ \aleph
// η \eta ω \omega υ \upsilon % \varrho Π \Pi i \beth
// γ \gamma φ \phi ξ \xi ς \varsigma Ψ \Psi k \daleth
// ι \iota π \pi ζ \zeta ϑ \vartheta Σ \Sigma ג\ gimel
