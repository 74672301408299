import React, { memo, useMemo } from 'react'
import { AudioIcon, ImgIcon, VideoIcon } from 'MediaUploadDrawer/Icons/AllIcon'
import Space from 'antd/lib/space'
import Card from 'antd/lib/card'
import Typography from 'antd/lib/typography'
import { TypeOfMediaProps } from 'MediaUploadDrawer/Interface/Media.interface'
import Box from 'designSystem/Box'

interface SwitchMediaBarProps extends TypeOfMediaProps {
  children: JSX.Element
  setType: (type: 'IMG' | 'VIDEO' | 'AUDIO') => void
  availableType: [string]
}

export default memo(function SwitchMediaBar({
  type,
  setType,
  children,
  availableType
}: SwitchMediaBarProps) {
  const switchMedia = useMemo(() => {
    const isImgAvailable = availableType.includes('IMG')
    const isVideoAvailable = availableType.includes('Video')
    const isSoundAvailable = availableType.includes('Sound')
    const isAllAvailable = availableType.includes('ALL')
    return (
      <Space size={16}>
        {(isImgAvailable || isAllAvailable) && (
          <div onClick={() => setType('IMG')}>
            <ImgIcon active={type === 'IMG'} />
          </div>
        )}
        {(isVideoAvailable || isAllAvailable) && (
          <div onClick={() => setType('VIDEO')}>
            <VideoIcon active={type === 'VIDEO'} />
          </div>
        )}
        {(isSoundAvailable || isAllAvailable) && (
          <div onClick={() => setType('AUDIO')}>
            <AudioIcon active={type === 'AUDIO'} />
          </div>
        )}
      </Space>
    )
  }, [type, ImgIcon, VideoIcon, AudioIcon])

  return (
    <Card
      title={
        <Box direction='column'>
          <Typography.Text
            strong
            style={{ color: '#242938', fontSize: '16px' }}
          >
            Media
          </Typography.Text>
          <Typography.Text style={{ color: '#80879B', fontSize: '14px' }}>
            Select 1 media
          </Typography.Text>
        </Box>
      }
      extra={switchMedia}
    >
      {children}
    </Card>
  )
})
