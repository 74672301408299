import React, { memo, useCallback, useEffect, useState } from 'react'
import Drawer from 'antd/lib/drawer'
import styled from 'styled-components'
import Space from 'antd/lib/space'
import Typography from 'antd/lib/typography'
import { Media } from 'MediaUploadDrawer/Interface/Media.interface'
import RenderTab from 'MediaUploadDrawer/Components/Tab/RenderTab'
import SwitchMediaBar from 'MediaUploadDrawer/Components/SwitchMediaBar/SwitchMediaBar'
import Box from 'designSystem/Box'
import BaseButton from 'designSystem/BaseButton'


interface MediaUploadProps {
  uploadFunction?: any
  onClose: () => void
  visible: boolean
  imageReceive?: string
  youtubeReceive?: string
  audioReceive?: string
  defaultType?: 'IMG' | 'VIDEO' | 'AUDIO'
  availableType?: [string]
}

export default memo(function MediaUpload({
  visible,
  onClose,
  imageReceive,
  youtubeReceive,
  audioReceive,
  uploadFunction,
  defaultType = 'IMG',
  availableType = ['ALL']
}: MediaUploadProps) {
  const [type, setType] = useState<'IMG' | 'VIDEO' | 'AUDIO'>(defaultType)
  const [imageUpload, setImageUpload] = useState<string | undefined>()
  const [youtubeUrl, setYoutubeUrl] = useState<string | undefined>()
  const [audioUpload, setAudioUpload] = useState<string | undefined>()
  const [onUploading, setOnuploading] = useState<boolean>(false)
  const [videoStartTime, setVideoStartTime] = useState<number | undefined>()
  const [videoEndTime, setVideoEndTime] = useState<number | undefined>()
  const [videoDuration, setVideoDuration] = useState<number | undefined>()
  const [isImageChange, setIsImageChange] = useState<boolean>(false)
  const [isAudioChange, setIsAudioChange] = useState<boolean>(false)

  useEffect(() => {
    if (defaultType) {
      setType(defaultType)
    }
  }, [defaultType])

  const discardHandler = useCallback(() => {
    onClose()
    setImageUpload(undefined)
    setYoutubeUrl(undefined)
    setAudioUpload(undefined)
    setOnuploading(false)
    setVideoStartTime(undefined)
    setVideoDuration(undefined)
    setVideoDuration(undefined)
    setIsImageChange(false)
    setIsAudioChange(false)
  }, [])

  const generateUrlYoutube = useCallback(() => {
    if (youtubeUrl) {
      if (videoStartTime === 0 && videoEndTime === videoDuration) {
        return youtubeUrl
      } else {
        if (videoStartTime && videoEndTime) {
          return `${youtubeUrl}?start=${videoStartTime}&end=${videoEndTime}`
        } else {
          return youtubeUrl
        }
      }
    } else {
      return false
    }
  }, [videoStartTime, videoEndTime, videoDuration, youtubeUrl])

  const uploadHandler = useCallback(async () => {
    let media: Media = {
      type: type,
      image: type === 'IMG' && imageUpload,
      youtubeURL: type === 'VIDEO' && generateUrlYoutube(),
      audio: type === 'AUDIO' && audioUpload
    }
    try {
      if (
        (type === 'IMG' && isImageChange) ||
        (type === 'AUDIO' && isAudioChange)
      ) {
        discardHandler()
        return
      }
      setOnuploading(true)
      await uploadFunction(media)
      setOnuploading(false)
      discardHandler()
    } catch (error) {
      console.error(error)
      setOnuploading(false)
      discardHandler()
    }
  }, [
    type,
    imageUpload,
    youtubeUrl,
    audioUpload,
    uploadFunction,
    setOnuploading,
    discardHandler,
    generateUrlYoutube,
    isImageChange,
    isAudioChange
  ])

  const getType = useCallback((type: 'IMG' | 'VIDEO' | 'AUDIO') => {
    setType(type)
  }, [])

  return (
    <UploadDrawer
      visible={visible}
      maskClosable={false}
      closable={false}
      destroyOnClose={true}
      width='486px'
      placement='right'
      title={
        <Box justify='space-between'>
          <Space>
            <Typography style={{ color: '#80879B' }}>Add Media</Typography>
          </Space>
          <Space>
            <BaseButton onClick={() => discardHandler()} disabled={onUploading}>
              Discard
            </BaseButton>
            <BaseButton
              type='primary'
              onClick={() => uploadHandler()}
              disabled={onUploading}
            >
              Confirm
            </BaseButton>
          </Space>
        </Box>
      }
    >
      {onUploading && <Loading />}
      <SwitchMediaBar
        availableType={availableType}
        type={type}
        setType={getType}
      >
        <RenderTab
          type={type}
          setType={getType}
          imageReceive={imageReceive}
          youtubeReceive={youtubeReceive}
          audioReceive={audioReceive}
          imageUpload={imageUpload}
          setImageUpload={setImageUpload}
          youtubeUrl={youtubeUrl}
          setYoutubeUrl={setYoutubeUrl}
          audioUpload={audioUpload}
          setAudioUpload={setAudioUpload}
          onUploading={onUploading}
          setOnuploading={setOnuploading}
          setVideoStartTime={setVideoStartTime}
          setVideoEndTime={setVideoEndTime}
          videoDuration={videoDuration}
          setVideoDuration={setVideoDuration}
          setIsImageChange={setIsImageChange}
          setIsAudioChange={setIsAudioChange}
        />
      </SwitchMediaBar>
    </UploadDrawer>
  )
})

const UploadDrawer = styled(Drawer)`
  display: flex;

  .ant-drawer-header {
    position: relative;
    padding: 16px 16px;
    color: rgba(0, 0, 0, 0.85);
    background: #fff;
    border-bottom: 1px solid #f0f0f0;
    border-radius: 2px 2px 0 0;
  }
  .ant-drawer-body {
    padding: 0;
    position: relative;
  }
  .ant-card-bordered {
    border: none;
  }
  .ant-card-head {
    padding: 0px 16px;
    border-bottom: none;
    font-weight: normal;
  }
  .ant-card-body {
    padding: 0px 16px;
  }
  .embed.embed-youtube {
    width: 100%;
    height: 100%;
  }
`

const Loading = styled.div`
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  position: absolute;
  z-index: 10;
  background-color: #ffffff80;
`
