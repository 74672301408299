import React, { memo, useRef, useCallback, Fragment } from "react";
import styled from 'styled-components'
import Spin from 'antd/lib/spin'
import Button from 'antd/lib/button'
import { LoadingOutlined } from '@ant-design/icons'
import { Bin, Speaker } from 'MediaUploadDrawer/Icons/AllIcon'
import { Space } from "antd";
import Typography from 'antd/lib/typography'

interface AudioTapProps {
    audioReceive?: string
    audioUpload?: string
    setAudioUpload: (audio: string) => void
    onUploading: boolean
    setOnuploading: (loading: boolean) => void
    receiveAudioFromFetch: boolean
    deleteMedia: (type: 'AUDIO') => void
}

export default memo(function AudioTab({
    audioReceive,
    onUploading: loading,
    setOnuploading,
    setAudioUpload,
    audioUpload: audio,
    receiveAudioFromFetch,
    deleteMedia
}: AudioTapProps) {
    const hiddenFileInputAudio = useRef<any>(null)

    const handleClick = () => {
        hiddenFileInputAudio.current.click()
    }

    const deleteAudioFunction = () => {
        deleteMedia('AUDIO')
        hiddenFileInputAudio.current.value = "";
    }

    const uploadAudio = useCallback(async (e) => {
        try {
            setOnuploading(true)
            const result = await e.target.files[0]
            setAudioUpload(result)
            setOnuploading(false)
        } catch (err) {
            console.error(err)
            setOnuploading(false)
        }
    }, [setAudioUpload, setOnuploading])

    return (
        <AudioContainer height='170px'>
            <ImageUpload
                id='uploadAudio'
                ref={hiddenFileInputAudio}
                type='file'
                accept='.mp3'
                onChange={(e) => {
                    uploadAudio(e)
                }}
            />
            {loading ? (
                <SpinStyle
                    indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />}
                />
            ) : (
                <Fragment>
                    {audio || receiveAudioFromFetch ? (
                        <AudioPlayer>
                            {/* <img src={icon_audio} alt="autio" width="80px" height="auto" /> */}
                            <Speaker />
                            <audio
                                controls
                                controlsList='nodownload'
                                src={receiveAudioFromFetch ? audioReceive : URL.createObjectURL(audio)}

                                style={{
                                    display: 'flex',
                                    width: "85%",
                                    marginRight: '15%',
                                    height: "40px",
                                }}
                            >
                                Your browser does not support the
                                <code>audio</code> element.
                            </audio>
                            <BinContainer
                                onClick={() => {
                                    deleteAudioFunction()
                                }}
                            >
                                <Bin />
                            </BinContainer>
                        </AudioPlayer>
                    ) : (
                        <Space direction='vertical' align='center'>
                            <CustomBtn type='primary' onClick={handleClick}>
                                Upload mp3 File
                            </CustomBtn>
                            <Typography.Text style={{ color: '#2D3243' }}>
                                Supports: mp3 (maximum 5MB)
                            </Typography.Text>
                        </Space>
                    )}
                </Fragment>
            )}
        </AudioContainer>
    )
})

const AudioContainer = styled.div<{ height: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${(props) => props.height};
  border-radius: 6px;
  border: 1px solid #e5e7ea;
  overflow: hidden;
  `

const ImageUpload = styled.input`
  display: none;
  `
const SpinStyle = styled(Spin)`
  position: absolute;
  z-index: 1;
  `
const CustomBtn = styled(Button)`
  border-radius: 6px;
  `
const AudioPlayer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  background: #1b202d;
  position: relative;
  padding: 30px 20px 16px 20px;
`;

const BinContainer = styled.div`
  position: absolute;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  padding: 0 5px;
  bottom: 16px;
  right: 20px;
  z-index: 5;
  cursor: pointer;
  background-color: #f1f3f4;
  transition: all 0.2s;
  
  :hover{
      background-color: #dde2ee;
  }
  /* border: 1px solid #BEC3CE; */
`