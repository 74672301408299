import React from 'react'

export const ImgIcon = ({ active }) => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        style={{ cursor: 'pointer' }}
    >
        <path
            d='M20.8 2H3.2C2.88174 2 2.57652 2.12643 2.35147 2.35147C2.12643 2.57652 2 2.88174 2 3.2V20.8C2 21.1183 2.12643 21.4235 2.35147 21.6485C2.57652 21.8736 2.88174 22 3.2 22H20.8C21.1183 22 21.4235 21.8736 21.6485 21.6485C21.8736 21.4235 22 21.1183 22 20.8V3.2C22 2.88174 21.8736 2.57652 21.6485 2.35147C21.4235 2.12643 21.1183 2 20.8 2ZM20 4V16.1L12.067 11.042C11.497 10.6782 10.8322 10.4909 10.1561 10.5038C9.48004 10.5166 8.82281 10.7289 8.267 11.114L4 14.07V4H20ZM4 20V16.47C4.04228 16.4546 4.08368 16.4369 4.124 16.417L9.4 12.761C9.63244 12.6005 9.90704 12.512 10.1895 12.5067C10.4719 12.5013 10.7497 12.5794 10.988 12.731L19.8 18.344C19.863 18.382 19.9301 18.4129 20 18.436V20H4Z'
            fill={active ? '#2680EB' : '#BEC3CE'}
        />
        <path
            d='M16.2761 10.1118C17.3972 10.1118 18.3061 9.2029 18.3061 8.08176C18.3061 6.96062 17.3972 6.05176 16.2761 6.05176C15.155 6.05176 14.2461 6.96062 14.2461 8.08176C14.2461 9.2029 15.155 10.1118 16.2761 10.1118Z'
            fill={active ? '#2680EB' : '#BEC3CE'}
        />
    </svg>
)

export const VideoIcon = ({ active }) => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        style={{ cursor: 'pointer' }}
    >
        <path
            d='M21 2H3C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3V21C2 21.2652 2.10536 21.5196 2.29289 21.7071C2.48043 21.8946 2.73478 22 3 22H21C21.2652 22 21.5196 21.8946 21.7071 21.7071C21.8946 21.5196 22 21.2652 22 21V3C22 2.73478 21.8946 2.48043 21.7071 2.29289C21.5196 2.10536 21.2652 2 21 2ZM13.588 18.234V20H10.245V18.234H13.588ZM4 16.234V8.081H20V16.234H4ZM10.41 6.081V4H13.752V6.081H10.41ZM20 6.081H15.752V4H20V6.081ZM8.41 4V6.081H4V4H8.41ZM4 18.234H8.245V20H4V18.234ZM15.588 20V18.234H20V20H15.588Z'
            fill={active ? '#2680EB' : '#BEC3CE'}
        />
        <path
            d='M12.4745 10.461L10.9005 9.54899C10.7903 9.48548 10.6653 9.45208 10.5381 9.45215C10.4109 9.45222 10.2859 9.48575 10.1758 9.54939C10.0657 9.61302 9.97419 9.70451 9.91059 9.81467C9.847 9.92483 9.8135 10.0498 9.81348 10.177V13.823C9.8135 13.9502 9.847 14.0751 9.91059 14.1853C9.97419 14.2955 10.0657 14.387 10.1758 14.4506C10.2859 14.5142 10.4109 14.5478 10.5381 14.5478C10.6653 14.5479 10.7903 14.5145 10.9005 14.451L12.4795 13.539L14.0585 12.627C14.1685 12.5634 14.2599 12.4721 14.3235 12.362C14.387 12.2519 14.4205 12.1271 14.4205 12C14.4205 11.8729 14.387 11.7481 14.3235 11.638C14.2599 11.5279 14.1685 11.4365 14.0585 11.373L12.4745 10.461Z'
            fill={active ? '#2680EB' : '#BEC3CE'}
        />
    </svg>
)

export const AudioIcon = ({ active }) => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 20 20'
        fill="#80879B"
        xmlns="http://www.w3.org/2000/svg"
        style={{ cursor: 'pointer' }}
    >
        <path
            d="M9.99935 18.2009C9.77834 18.2009 9.56637 18.1131 9.41009 17.9568C9.25381 17.8005 9.16602 17.5885 9.16602 17.3675V2.63253C9.16602 2.41151 9.25381 2.19955 9.41009 2.04327C9.56637 1.88699 9.77834 1.79919 9.99935 1.79919C10.2204 1.79919 10.4323 1.88699 10.5886 2.04327C10.7449 2.19955 10.8327 2.41151 10.8327 2.63253V17.3675C10.8327 17.5885 10.7449 17.8005 10.5886 17.9568C10.4323 18.1131 10.2204 18.2009 9.99935 18.2009Z"
            fill={active ? '#2680EB' : '#BEC3CE'}
        />
        <path
            d="M13.3275 14.2708C13.1065 14.2708 12.8945 14.183 12.7382 14.0267C12.5819 13.8704 12.4941 13.6585 12.4941 13.4375V6.56246C12.4941 6.34145 12.5819 6.12948 12.7382 5.9732C12.8945 5.81692 13.1065 5.72913 13.3275 5.72913C13.5485 5.72913 13.7604 5.81692 13.9167 5.9732C14.073 6.12948 14.1608 6.34145 14.1608 6.56246V13.4375C14.1608 13.5469 14.1393 13.6553 14.0974 13.7564C14.0555 13.8575 13.9941 13.9493 13.9167 14.0267C13.8393 14.1041 13.7475 14.1655 13.6464 14.2074C13.5453 14.2492 13.4369 14.2708 13.3275 14.2708Z"
            fill={active ? '#2680EB' : '#BEC3CE'}
        />
        <path
            d="M6.65755 16C6.43654 16 6.22458 15.9122 6.0683 15.7559C5.91202 15.5996 5.82422 15.3877 5.82422 15.1667V4.83748C5.82422 4.61647 5.91202 4.40451 6.0683 4.24823C6.22458 4.09195 6.43654 4.00415 6.65755 4.00415C6.87857 4.00415 7.09053 4.09195 7.24681 4.24823C7.40309 4.40451 7.49089 4.61647 7.49089 4.83748V15.1667C7.49089 15.2761 7.46933 15.3844 7.42745 15.4856C7.38557 15.5867 7.32419 15.6785 7.24681 15.7559C7.16943 15.8333 7.07756 15.8947 6.97645 15.9366C6.87535 15.9784 6.76699 16 6.65755 16Z"
            fill={active ? '#2680EB' : '#BEC3CE'}
        />
        <path
            d="M16.6868 12.7183C16.4658 12.7183 16.2539 12.6305 16.0976 12.4742C15.9413 12.3179 15.8535 12.106 15.8535 11.885V8.11495C15.8535 7.89394 15.9413 7.68197 16.0976 7.52569C16.2539 7.36941 16.4658 7.28162 16.6868 7.28162C16.9079 7.28162 17.1198 7.36941 17.2761 7.52569C17.4324 7.68197 17.5202 7.89394 17.5202 8.11495V11.885C17.5202 12.106 17.4324 12.3179 17.2761 12.4742C17.1198 12.6305 16.9079 12.7183 16.6868 12.7183Z"
            fill={active ? '#2680EB' : '#BEC3CE'}
        />
        <path
            d="M3.3138 13.1492C3.09279 13.1492 2.88083 13.0614 2.72455 12.9051C2.56827 12.7488 2.48047 12.5368 2.48047 12.3158V7.68416C2.48047 7.46315 2.56827 7.25119 2.72455 7.09491C2.88083 6.93863 3.09279 6.85083 3.3138 6.85083C3.53482 6.85083 3.74678 6.93863 3.90306 7.09491C4.05934 7.25119 4.14714 7.46315 4.14714 7.68416V12.3158C4.14714 12.5368 4.05934 12.7488 3.90306 12.9051C3.74678 13.0614 3.53482 13.1492 3.3138 13.1492Z"
            fill={active ? '#2680EB' : '#BEC3CE'}
        />
    </svg>

)

export const Bin = () => (
    <svg
        width='19'
        height='19'
        viewBox='0 0 19 19'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M7.82837 7.82129C7.71863 7.82129 7.60995 7.84292 7.50857 7.88494C7.40719 7.92696 7.31508 7.98856 7.23751 8.0662C7.15995 8.14384 7.09844 8.236 7.05651 8.33743C7.01459 8.43885 6.99306 8.54754 6.99316 8.65729V13.724C6.99985 13.9411 7.09082 14.1472 7.24679 14.2984C7.40277 14.4496 7.6115 14.5342 7.82877 14.5342C8.04604 14.5342 8.25477 14.4496 8.41075 14.2984C8.56672 14.1472 8.65769 13.9411 8.66437 13.724V8.65729C8.66437 8.43557 8.5763 8.22293 8.41952 8.06615C8.26273 7.90937 8.05009 7.82129 7.82837 7.82129Z'
            // fill='#80879B'
            fill='#000'
        />
        <path
            d='M11.171 7.82129C10.9492 7.82129 10.7366 7.90937 10.5798 8.06615C10.423 8.22293 10.335 8.43557 10.335 8.65729V13.724C10.3416 13.9411 10.4326 14.1472 10.5886 14.2984C10.7446 14.4496 10.9533 14.5342 11.1706 14.5342C11.3878 14.5342 11.5966 14.4496 11.7525 14.2984C11.9085 14.1472 11.9995 13.9411 12.0062 13.724V8.65729C12.0062 8.43571 11.9182 8.22319 11.7616 8.06643C11.605 7.90967 11.3925 7.8215 11.171 7.82129Z'
            // fill='#80879B'
            fill='#000'
        />
        <path
            d='M16.876 4.20898H2.39328C2.17612 4.21567 1.97009 4.30664 1.81884 4.46261C1.66759 4.61859 1.58301 4.82732 1.58301 5.04459C1.58301 5.26186 1.66759 5.47059 1.81884 5.62656C1.97009 5.78254 2.17612 5.87351 2.39328 5.88019H3.23324V14.826C3.23492 15.4974 3.50236 16.1408 3.97709 16.6155C4.45182 17.0902 5.09521 17.3577 5.76658 17.3594H13.0673C13.7388 17.3579 14.3823 17.0905 14.857 16.6157C15.3318 16.141 15.5992 15.4975 15.6007 14.826V5.88019H16.8673C17.0845 5.87351 17.2905 5.78254 17.4418 5.62656C17.593 5.47059 17.6776 5.26186 17.6776 5.04459C17.6776 4.82732 17.593 4.61859 17.4418 4.46261C17.2905 4.30664 17.0845 4.21567 16.8673 4.20898H16.876ZM13.0713 15.7808H5.77053C5.51896 15.7795 5.27805 15.679 5.10016 15.5011C4.92227 15.3233 4.82178 15.0823 4.82053 14.8308V5.88019H14.0245V14.826C14.0246 14.9513 14 15.0754 13.9521 15.1912C13.9043 15.3069 13.8341 15.4122 13.7456 15.5008C13.6571 15.5895 13.552 15.6598 13.4363 15.7079C13.3206 15.7559 13.1966 15.7807 13.0713 15.7808Z'
            // fill='#80879B'
            fill='#000'
        />
        <path
            d='M7.19473 3.09446H12.2076C12.3195 3.0979 12.4309 3.07884 12.5353 3.03839C12.6397 2.99795 12.7349 2.93695 12.8153 2.85902C12.8956 2.78109 12.9595 2.6878 13.0032 2.58471C13.0468 2.48161 13.0693 2.3708 13.0693 2.25885C13.0693 2.1469 13.0468 2.03609 13.0032 1.933C12.9595 1.8299 12.8956 1.73662 12.8153 1.65868C12.7349 1.58075 12.6397 1.51975 12.5353 1.47931C12.4309 1.43887 12.3195 1.4198 12.2076 1.42325H7.19473C7.08283 1.4198 6.97138 1.43887 6.86699 1.47931C6.7626 1.51975 6.6674 1.58075 6.58703 1.65868C6.50666 1.73662 6.44276 1.8299 6.39913 1.933C6.35549 2.03609 6.33301 2.1469 6.33301 2.25885C6.33301 2.3708 6.35549 2.48161 6.39913 2.58471C6.44276 2.6878 6.50666 2.78109 6.58703 2.85902C6.6674 2.93695 6.7626 2.99795 6.86699 3.03839C6.97138 3.07884 7.08283 3.0979 7.19473 3.09446Z'
            // fill='#80879B'
            fill='#000'
        />
    </svg>
)

export const MediaIcon = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='-4 -4 36 32'
        width='22pt'
        height='22pt'
    >
        <defs>
            <clipPath id='_clipPath_CW7fuc7uR2w6ANylU02e8OCOXLRIsG8j'>
                <rect width='32' height='32' />
            </clipPath>
        </defs>
        <g clipPath='url(#_clipPath_CW7fuc7uR2w6ANylU02e8OCOXLRIsG8j)'>
            <g>
                <path
                    d=' M 22 4 L 4 4 C 3.735 4 3.48 4.105 3.293 4.293 C 3.105 4.48 3 4.735 3 5 L 3 23 C 3 23.265 3.105 23.52 3.293 23.707 C 3.48 23.895 3.735 24 4 24 L 22 24 C 22.265 24 22.52 23.895 22.707 23.707 C 22.895 23.52 23 23.265 23 23 L 23 5 C 23 4.735 22.895 4.48 22.707 4.293 C 22.52 4.105 22.265 4 22 4 Z  M 14.588 20.234 L 14.588 22 L 11.245 22 L 11.245 20.234 L 14.588 20.234 Z  M 5 18.234 L 5 10.081 L 21 10.081 L 21 18.234 L 5 18.234 Z  M 11.41 8.081 L 11.41 6 L 14.752 6 L 14.752 8.081 L 11.41 8.081 Z  M 21 8.081 L 16.752 8.081 L 16.752 6 L 21 6 L 21 8.081 Z  M 9.41 6 L 9.41 8.081 L 5 8.081 L 5 6 L 9.41 6 Z  M 5 20.234 L 9.245 20.234 L 9.245 22 L 5 22 L 5 20.234 Z  M 16.588 22 L 16.588 20.234 L 21 20.234 L 21 22 L 16.588 22 Z '
                    fill='rgb(190,195,206)'
                />
                <path
                    d=' M 13.475 12.461 L 11.901 11.549 C 11.79 11.485 11.665 11.452 11.538 11.452 C 11.411 11.452 11.286 11.486 11.176 11.549 C 11.066 11.613 10.974 11.705 10.911 11.815 C 10.847 11.925 10.814 12.05 10.813 12.177 L 10.813 15.823 C 10.814 15.95 10.847 16.075 10.911 16.185 C 10.974 16.296 11.066 16.387 11.176 16.451 C 11.286 16.514 11.411 16.548 11.538 16.548 C 11.665 16.548 11.79 16.515 11.901 16.451 L 13.48 15.539 L 15.059 14.627 C 15.169 14.563 15.26 14.472 15.324 14.362 C 15.387 14.252 15.421 14.127 15.421 14 C 15.421 13.873 15.387 13.748 15.324 13.638 C 15.26 13.528 15.169 13.437 15.059 13.373 L 13.475 12.461 Z '
                    fill='rgb(190,195,206)'
                />
            </g>
            <g>
                <path
                    d=' M 26.87 27.753 C 26.625 27.752 26.418 27.568 26.39 27.324 C 26.361 27.081 26.518 26.853 26.757 26.795 C 28.676 26.334 30.029 24.618 30.029 22.644 C 30.029 20.67 28.676 18.954 26.757 18.493 C 26.505 18.423 26.354 18.165 26.415 17.911 C 26.476 17.656 26.728 17.496 26.985 17.549 C 29.34 18.115 31 20.222 31 22.644 C 31 25.067 29.34 27.173 26.985 27.74 C 26.947 27.749 26.909 27.753 26.87 27.753 Z '
                    fill='rgb(90,184,247)'
                />
                <path
                    d=' M 26.871 25.384 C 26.629 25.384 26.424 25.205 26.39 24.965 C 26.357 24.726 26.505 24.498 26.738 24.431 L 26.738 24.431 C 27.535 24.203 28.085 23.473 28.085 22.643 C 28.085 21.813 27.535 21.084 26.738 20.855 C 26.485 20.777 26.341 20.512 26.414 20.257 C 26.487 20.003 26.749 19.854 27.005 19.921 L 27.005 19.921 C 28.217 20.271 29.052 21.381 29.052 22.643 C 29.052 23.905 28.217 25.015 27.005 25.364 C 26.962 25.377 26.916 25.384 26.871 25.384 Z '
                    fill='rgb(90,184,247)'
                />
                <path
                    d=' M 22.236 19.594 L 21.388 20.422 C 21.287 20.52 21.152 20.575 21.011 20.576 L 19.652 20.576 C 19.508 20.576 19.369 20.633 19.267 20.735 C 19.165 20.837 19.108 20.976 19.108 21.12 L 19.108 24.17 C 19.108 24.315 19.165 24.453 19.267 24.555 C 19.369 24.657 19.508 24.714 19.652 24.714 L 21.008 24.714 C 21.149 24.715 21.284 24.77 21.386 24.868 L 22.233 25.697 L 24.436 27.846 C 24.593 27.998 24.826 28.042 25.027 27.957 C 25.229 27.872 25.36 27.675 25.36 27.456 L 25.36 17.833 C 25.361 17.614 25.229 17.416 25.028 17.331 C 24.826 17.246 24.593 17.29 24.436 17.443 L 22.236 19.594 Z '
                    fill='rgb(90,184,247)'
                />
            </g>
        </g>
    </svg>
)

export const Logo = () => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M20.8 2H3.2C2.88174 2 2.57652 2.12643 2.35147 2.35147C2.12643 2.57652 2 2.88174 2 3.2V20.8C2 21.1183 2.12643 21.4235 2.35147 21.6485C2.57652 21.8736 2.88174 22 3.2 22H20.8C21.1183 22 21.4235 21.8736 21.6485 21.6485C21.8736 21.4235 22 21.1183 22 20.8V3.2C22 2.88174 21.8736 2.57652 21.6485 2.35147C21.4235 2.12643 21.1183 2 20.8 2ZM20 4V16.1L12.067 11.042C11.497 10.6782 10.8322 10.4909 10.1561 10.5038C9.48004 10.5166 8.82281 10.7289 8.267 11.114L4 14.07V4H20ZM4 20V16.47C4.04228 16.4546 4.08368 16.4369 4.124 16.417L9.4 12.761C9.63244 12.6005 9.90704 12.512 10.1895 12.5067C10.4719 12.5013 10.7497 12.5794 10.988 12.731L19.8 18.344C19.863 18.382 19.9301 18.4129 20 18.436V20H4Z'
            fill='#2680EB'
        />
        <path
            d='M16.2761 10.1118C17.3972 10.1118 18.3061 9.2029 18.3061 8.08176C18.3061 6.96062 17.3972 6.05176 16.2761 6.05176C15.155 6.05176 14.2461 6.96062 14.2461 8.08176C14.2461 9.2029 15.155 10.1118 16.2761 10.1118Z'
            fill='#2680EB'
        />
    </svg>
)

export const YoutubeIcon = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        height='72'
        width='108'
        viewBox='-35.20005 -41.33325 305.0671 247.9995'
    >
        <path
            d='M229.763 25.817c-2.699-10.162-10.65-18.165-20.748-20.881C190.716 0 117.333 0 117.333 0S43.951 0 25.651 4.936C15.553 7.652 7.6 15.655 4.903 25.817 0 44.236 0 82.667 0 82.667s0 38.429 4.903 56.85C7.6 149.68 15.553 157.681 25.65 160.4c18.3 4.934 91.682 4.934 91.682 4.934s73.383 0 91.682-4.934c10.098-2.718 18.049-10.72 20.748-20.882 4.904-18.421 4.904-56.85 4.904-56.85s0-38.431-4.904-56.85'
            fill='red'
        />
        <path d='M93.333 117.559l61.333-34.89-61.333-34.894z' fill='#fff' />
    </svg>
)

export const Speaker = () => (
    <svg width="64" height="64" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path id="Path_600" data-name="Path 600"
            d="M14.886,20.116a.772.772,0,0,1-.18-1.522,6.782,6.782,0,0,0,0-13.189.772.772,0,0,1,.362-1.5,8.326,8.326,0,0,1,0,16.19A.8.8,0,0,1,14.886,20.116Z"
            fill="#BEC3CE"></path>
        <path id="Path_601" data-name="Path 601"
            d="M14.887,16.352a.772.772,0,0,1-.212-1.514h0a2.954,2.954,0,0,0,0-5.68A.772.772,0,0,1,15.1,7.674h0a4.5,4.5,0,0,1,0,8.647A.78.78,0,0,1,14.887,16.352Z"
            fill="#BEC3CE"></path>
        <path id="Path_602" data-name="Path 602"
            d="M7.523,7.154,6.177,8.469a.867.867,0,0,1-.6.245H3.419a.864.864,0,0,0-.865.864v4.846a.864.864,0,0,0,.865.864H5.573a.867.867,0,0,1,.6.245l1.346,1.316,3.5,3.414a.865.865,0,0,0,1.468-.619V4.356a.864.864,0,0,0-1.468-.619Z"
            fill="#BEC3CE"></path>
    </svg>
)