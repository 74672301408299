import React, { Fragment, memo, useCallback } from "react";
import styled from 'styled-components'
import Space from 'antd/lib/space'
import Typography from 'antd/lib/typography'
import { Logo, Bin } from 'MediaUploadDrawer/Icons/AllIcon'
import { LoadingOutlined } from '@ant-design/icons'
import Spin from 'antd/lib/spin'
import Image from 'antd/lib/image'

interface ImageTabProps {
    imageReceive?: string
    imageUpload?: string
    setImageUpload: (img: string) => void
    onUploading: boolean
    setOnuploading: (loading: boolean) => void
    deleteMedia: (type: 'IMG') => void
    receiveImgFromFetch: boolean
}

export default memo(function ImageTab({
    imageReceive,
    imageUpload: image,
    setImageUpload,
    onUploading: loading,
    setOnuploading,
    deleteMedia,
    receiveImgFromFetch
}: ImageTabProps) {

    const uploadImage = useCallback(
        async (e) => {
            try {
                setOnuploading(true)
                const result = await e.target.files[0]
                setImageUpload(result)
                setOnuploading(false)
            } catch (err) {
                console.error(err)
                setOnuploading(false)
            }
        },
        [setImageUpload, setOnuploading]
    )

    return (
        <Fragment>
            {!image && (
                <ImageUpload
                    id='uploadImg'
                    type='file'
                    accept='.png,.jpeg,.jpg'
                    onChange={(e) => {
                        uploadImage(e)
                    }}
                />
            )}

            <Container height='274px'>
                {loading ? (
                    <SpinStyle
                        indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />}
                    />
                ) : (
                    <Fragment>
                        {image || receiveImgFromFetch ? (
                            <div
                                style={{
                                    height: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <BinContainer
                                    onClick={() => {
                                        deleteMedia('IMG')
                                    }}
                                >
                                    <Bin />
                                </BinContainer>
                                <ImgContainer>
                                    <ImageStyle
                                        src={receiveImgFromFetch ? imageReceive : URL.createObjectURL(image)}
                                        alt='icon_photo'
                                        style={{ width: 'inherit', height: 'inherit', objectFit: 'contain' }}
                                    />
                                </ImgContainer>
                            </div>
                        ) : (
                            <label
                                htmlFor='uploadImg'
                                style={{
                                    border: '1px dashed #F2F3F4',
                                    width: '240px',
                                    height: '240px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '6px',
                                    cursor: 'pointer'
                                }}
                            >
                                <Space direction='vertical' align='center'>
                                    <Logo />
                                    <Typography.Text
                                        strong
                                        style={{ textAlign: 'center', color: '#2680EB' }}
                                    >
                                        + Add Image
                                    </Typography.Text>
                                </Space>
                            </label>
                        )}
                    </Fragment>
                )}
            </Container>
        </Fragment>
    )
})

const ImageUpload = styled.input`
  display: none;
`
const Container = styled.div<{ height: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${(props) => props.height};
  border-radius: 6px;
  border: 1px solid #e5e7ea;
  /* cursor: pointer; */
  overflow: hidden;
  position: relative;
`
const ImgContainer = styled.div`
  height: 240px;
  width: 240px;
  object-fit: cover;
  overflow: hidden;
  background-color: #000;
  display: flex;
  justify-content: center; 
  align-items: center;
  border-radius: 6px;

  .ant-image {
    width: inherit;
    height: inherit;
  }
`
const BinContainer = styled.div`
  position: absolute;
  background-color: #fff;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  padding: 0 5px;
  bottom: 16px;
  right: 16px;
  z-index: 5;
  cursor: pointer;
  border: 1px solid #e5e7ea;
  transition: all 0.2s;
  
  :hover{
    background-color: #f1f3f4;
}
`
const SpinStyle = styled(Spin)`
  position: absolute;
  z-index: 1;
`
const ImageStyle = styled(Image)`
  border-radius: var(--border-radius);
    width: inherit;
    height: inherit;

    .ant-image {
        width: inherit;
        height: inherit;
    }

  &.ant-image-img {
    width: inherit;
    height: inherit;
    object-fit: cover;
  }

  &.ant-image-img > img{
    width: inherit;
    height: inherit;
    object-fit: cover;
  } 

  img{
    width: inherit;
    height: inherit;
    object-fit: cover;
  } 
`
