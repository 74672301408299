import React, { memo, useCallback, useEffect, useState, useMemo } from "react";
import ImageTab from 'MediaUploadDrawer/Components/Tab/ImageTab/ImageTab'
import VideoTab from 'MediaUploadDrawer/Components/Tab/VideoTab/VideoTab'
import AudioTab from 'MediaUploadDrawer/Components/Tab/AudioTab/AudioTab'
import { TypeOfMediaProps } from 'MediaUploadDrawer/Interface/Media.interface'

interface RenderTabProps extends TypeOfMediaProps {
    setType: (type: 'IMG' | 'VIDEO' | 'AUDIO') => void
    imageReceive?: string
    youtubeReceive?: string
    audioReceive?: string
    imageUpload: string | undefined
    setImageUpload: (img: string | undefined) => void
    youtubeUrl: string | undefined
    setYoutubeUrl: (url: string | undefined) => void
    audioUpload: string | undefined
    setAudioUpload: (audio: string | undefined) => void
    onUploading: boolean
    setOnuploading: (loading: boolean) => void
    setVideoStartTime: (time: number | undefined) => void
    setVideoEndTime: (time: number | undefined) => void
    videoDuration: number | undefined
    setVideoDuration: (duration: number | undefined) => void
    setIsImageChange: (change: boolean) => void
    setIsAudioChange: (change: boolean) => void
}

export default memo(function RenderTab({
    type,
    setType,
    imageReceive,
    youtubeReceive,
    audioReceive,
    imageUpload,
    youtubeUrl,
    audioUpload,
    setImageUpload,
    setYoutubeUrl,
    setAudioUpload,
    onUploading,
    setOnuploading,
    setVideoStartTime,
    setVideoEndTime,
    videoDuration,
    setVideoDuration,
    setIsImageChange,
    setIsAudioChange
}: RenderTabProps) {
    const [receiveImgFromFetch, setReceiveImgFromFetch] = useState<boolean>(false)
    const [receiveAudioFromFetch, setReceiveAudioFromFetch] = useState<boolean>(false)

    const deleteMedia = useCallback((type: ("IMG" | "AUDIO")) => {
        switch (type) {
            case 'IMG':
                setImageUpload(undefined)
                setReceiveImgFromFetch(false)
                setIsImageChange(false)
                break
            case 'AUDIO':
                setAudioUpload(undefined)
                setReceiveAudioFromFetch(false)
                setIsAudioChange(false)
                break
        }
    }, [
        setImageUpload,
        setAudioUpload,
        setReceiveImgFromFetch,
        setReceiveAudioFromFetch,
        setIsImageChange,
        setIsAudioChange
    ])

    useEffect(() => {
        if (imageReceive) {
            setType('IMG')
            setReceiveImgFromFetch(true)
            setIsImageChange(true)
        } else if (youtubeReceive) {
            setType('VIDEO')
            setYoutubeUrl(youtubeReceive)
        } else if (audioReceive) {
            setType('AUDIO')
            setReceiveAudioFromFetch(true)
            setIsAudioChange(true)
        }
    }, [
        imageReceive,
        youtubeReceive,
        audioReceive,
        setType,
        setReceiveImgFromFetch,
        setIsImageChange,
        setIsAudioChange,
        setYoutubeUrl
    ])

    const renderImage = useMemo(() => {
        return (
            <ImageTab
                imageReceive={imageReceive}
                imageUpload={imageUpload}
                setImageUpload={setImageUpload}
                onUploading={onUploading}
                setOnuploading={setOnuploading}
                deleteMedia={deleteMedia}
                receiveImgFromFetch={receiveImgFromFetch}
            />
        )

    }, [
        imageReceive,
        imageUpload,
        setImageUpload,
        onUploading,
        setOnuploading,
        deleteMedia,
        receiveImgFromFetch
    ])

    const renderVideo = useMemo(() => {
        return (
            <VideoTab
                youtubeReceive={youtubeReceive}
                youtubeUrl={youtubeUrl}
                setYoutubeUrl={setYoutubeUrl}
                onUploading={onUploading}
                setOnuploading={setOnuploading}
                setVideoStartTime={setVideoStartTime}
                setVideoEndTime={setVideoEndTime}
                videoDuration={videoDuration}
                setVideoDuration={setVideoDuration}
            />
        )
    }, [
        youtubeReceive,
        youtubeUrl,
        setYoutubeUrl,
        onUploading,
        setOnuploading,
        setVideoStartTime,
        setVideoEndTime,
        videoDuration,
        setVideoDuration
    ])

    const renderAudio = useMemo(() => {
        return (
            <AudioTab
                audioReceive={audioReceive}
                audioUpload={audioUpload}
                setAudioUpload={setAudioUpload}
                onUploading={onUploading}
                setOnuploading={setOnuploading}
                receiveAudioFromFetch={receiveAudioFromFetch}
                deleteMedia={deleteMedia}
            />
        )
    }, [
        audioReceive,
        audioUpload,
        setAudioUpload,
        onUploading,
        setOnuploading,
        receiveAudioFromFetch
    ])

    const renderTab = useCallback((type: ('IMG' | 'VIDEO' | 'AUDIO')) => {
        switch (type) {
            case 'IMG':
                return renderImage
            case 'VIDEO':
                return renderVideo
            case 'AUDIO':
                return renderAudio

            default:
                return renderImage
        }
    }, [renderImage, renderVideo, renderAudio])

    return renderTab(type)
})