import React, { memo, useEffect, useCallback, useState, Fragment, useMemo } from "react";
import styled from 'styled-components'
import Input from 'antd/lib/input'
import Typography from 'antd/lib/typography'
import Space from 'antd/lib/space'
import YouTube from 'react-youtube'
import { YoutubeIcon } from 'MediaUploadDrawer/Icons/AllIcon'
import TimePicker from 'antd/lib/time-picker'
import moment from 'moment'

interface VideoTabProps {
    youtubeReceive?: string
    youtubeUrl?: string
    setYoutubeUrl: (url: string | undefined) => void
    onUploading: boolean
    setOnuploading: (loading: boolean) => void
    setVideoStartTime: (time: number | undefined) => void
    setVideoEndTime: (time: number | undefined) => void
    videoDuration: number | undefined
    setVideoDuration: (duration: number | undefined) => void
}

export default memo(function VideoTab({
    youtubeUrl,
    setYoutubeUrl,
    setVideoStartTime,
    setVideoEndTime,
    videoDuration,
    setVideoDuration
}: VideoTabProps) {

    const [youtubeID, setYoutubeID] = useState<string | undefined>()
    const [startTime, setStartTime] = useState('00:00:00')
    const [endTime, setEndTime] = useState('00:00:00')
    const [warning, setWarning] = useState<boolean>(true)

    //function transform to embeded url
    const getYoutubeID = useCallback(
        (url: string) => {
            const regExp = /(?:youtu\.be\/|youtube\.com(?:\/embed\/|\/v\/|\/watch\?v=|\/user\/\S+|\/ytscreeningroom\?v=|\/sandalsResorts#\w\/\w\/.*\/))([^\/&]{11})/
            const startRegex = /start=(.*[0-9])&end/
            const endeRegex = /end=(.*[0-9])/
            const match = url.match(regExp)
            const start = url.match(startRegex);
            const end = url.match(endeRegex)

            if (match && match[1].length === 11) {
                if (start?.[1] && end?.[1]) {
                    setVideoStartTime(Number(start?.[1]))
                    setVideoEndTime(Number(end?.[1]))
                } else {
                    setVideoStartTime(undefined)
                    setVideoEndTime(undefined)
                }
                setYoutubeUrl(`https://www.youtube.com/embed/${match[1]}`)
                setYoutubeID(match[1])
                setWarning(true)
            } else {
                setYoutubeUrl(undefined)
                setYoutubeID(undefined)
                if (!url) {
                    setWarning(true)
                } else {
                    setWarning(false)
                }

            }
        }, [setYoutubeUrl, setYoutubeID, setWarning])

    const timeStringToSecNumberType = (timeString: string) => {
        return moment(timeString, 'HH:mm:ss').diff(
            moment().startOf('day'),
            'seconds'
        )
    }

    const timeChange = (when: ('start' | 'end'), timeString: string) => {
        if (when === 'start') {
            setStartTime(timeString)
            setVideoStartTime(timeStringToSecNumberType(timeString))
        } else {
            setEndTime(timeString)
            setVideoEndTime(timeStringToSecNumberType(timeString))
        }
    }

    useEffect(() => {
        timeChange('start', moment.utc(Number(0) * 1000).format('HH:mm:ss'))
        timeChange('end', moment.utc(Number(videoDuration) * 1000).format('HH:mm:ss'))
    }, [videoDuration])

    //time validate
    useEffect(() => {
        const start = timeStringToSecNumberType(startTime)
        const end = timeStringToSecNumberType(endTime)
        const durationSec = Number(videoDuration)
        if (start > durationSec) {
            timeChange('start', '00:00:00')
        } else if (end > durationSec) {
            timeChange('end', moment.utc(Number(videoDuration) * 1000).format('HH:mm:ss'))
        } else if (start > end) {
            timeChange('start', endTime)
            timeChange('end', startTime)
        }
    }, [startTime, endTime, videoDuration])

    // transform to embeded url
    useEffect(() => {
        if (youtubeUrl) {
            getYoutubeID(youtubeUrl)
        }
    }, [youtubeUrl])

    const getDurationVideo = useCallback((e: any) => {
        const duration = e.target.getDuration()
        setVideoDuration(Math.floor(duration))
    }, [setVideoDuration])

    const renderYoutube = useMemo(() => {
        return (
            <YoutubePlayer
                videoId={youtubeID}
                containerClassName='embed embed-youtube'
                opts={{
                    playerVars: {
                        autoplay: 1
                    }
                }}
                onStateChange={(e) => {
                    getDurationVideo(e)
                }}
            />
        )
    }, [youtubeID])

    return (
        <Space direction='vertical' style={{ width: '100%' }}>
            <CustomInputYoutube
                addonBefore='URL Youtube Link'
                value={youtubeUrl}
                onChange={(e) => {
                    getYoutubeID(e.target.value)
                }}
                warning={warning}
            />
            {!youtubeUrl ? (
                <VideoContainer height='274px'>
                    <YoutubeIcon />
                    <Typography.Text style={{ textAlign: 'center', color: '#80879B' }}>
                        YouTube URL Preview
                    </Typography.Text>
                </VideoContainer>
            ) : (
                <Space direction='vertical' style={{ width: '100%' }}>
                    <Container height='274px' style={{ marginBottom: '10px' }}>
                        {renderYoutube}
                    </Container>
                    {videoDuration && (
                        <Fragment>
                            <Typography.Text style={{ color: '#2D3243' }}>
                                You can set the start and end of the video.
                            </Typography.Text>
                            <Space
                                direction='horizontal'
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginTop: '4px'
                                }}
                                size={0}
                            >
                                <CustomTimePicker>
                                    <Badge>Start at</Badge>
                                    <TimePicker
                                        style={{ display: 'flex', flex: 1 }}
                                        allowClear={false}
                                        value={moment(startTime, 'HH:mm:ss')}
                                        format='HH:mm:ss'
                                        onChange={(value) => {
                                            const timeString = moment(value).format('HH:mm:ss')
                                            timeChange('start', timeString)
                                        }}
                                        showNow={false}
                                    />
                                </CustomTimePicker>
                                <CustomTimePicker>
                                    <Badge>End at</Badge>
                                    <TimePicker
                                        style={{ display: 'flex', flex: 1 }}
                                        allowClear={false}
                                        value={moment(endTime, 'HH:mm:ss')}
                                        format='HH:mm:ss'
                                        onChange={(value) => {
                                            const timeString = moment(value).format('HH:mm:ss')
                                            timeChange('end', timeString)
                                        }}
                                        showNow={false}
                                    />
                                </CustomTimePicker>
                            </Space>
                        </Fragment>
                    )}
                </Space>
            )}
        </Space>
    )
})

const CustomInputYoutube = styled(Input) <{ warning: boolean }>`
  span.ant-input-group-addon {
    border-radius: 6px 0 0 6px;
  }

  
  .ant-input {
    color: ${(props) => (props.warning ? '' : 'red')};
    border-radius: 0 6px 6px 0;
    border-color: ${(props) => (props.warning ? '' : 'red')};
  }

  .ant-input:focus, .ant-input-focused{
    border-color: ${(props) => (props.warning ? '' : 'red')};
    box-shadow: ${(props) => (props.warning ? '' : '0 0 0 2px #ff000010 !important')}
  }
`
const VideoContainer = styled.div<{ height: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${(props) => props.height};
  border-radius: 6px;
  border: 1px solid #e5e7ea;
  overflow: hidden;
`
const Container = styled.div<{ height: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${(props) => props.height};
  border-radius: 6px;
  border: 1px solid #e5e7ea;
  /* cursor: pointer; */
  overflow: hidden;
  position: relative;
`
const YoutubePlayer = styled(YouTube)`
  width: 100%;
  height: 100%;
`
const CustomTimePicker = styled.div`
  display: flex;

  .ant-picker {
    border-radius: 0 6px 6px 0 !important;
    width: 145px;
  }
`
const Badge = styled.div`
  background-color: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  border: 1px solid #d9d9d9;
  border-radius: 6px 0 0 6px !important;
  border-right: 0;
`
